export interface IListResponse<T> {
  data: T[];
  total: number;
}

export enum IErrorCode {
  forbidden = 'forbidden',
  notFound = 'notFound',
  badRequest = 'badRequest',
}

export interface IError {
  code: IErrorCode;
  message: string;
  name: string;
}