import {
  CodeAccess,
  ICodeUser,
  ICode,
  IUser,
  CodeInvitationAction,
} from '@codex/shared/interfaces';

import api from './index';

export type shareCodeData = { emails: string[]; access: CodeAccess };
export type invitationActionData = { action: CodeInvitationAction };
export type ICodeUserResponse = { data: ICodeUser[] };
export type UpdatedSharedUserAccessResponse = Pick<ICodeUser, 'id' | 'access'>;
export type updateUserAccessConfig = {
  codeId: ICode['id'];
  userId: IUser['id'];
  access: CodeAccess;
};

export async function shareCode(codeId: number, data: shareCodeData): Promise<ICodeUserResponse> {
  const url = `/api/v1/codes/${codeId}/users`;
  const response = await api.post<ICodeUserResponse>(url, data);

  return response.data;
}

export async function getSharedCodeUsers(codeId: number): Promise<ICodeUserResponse> {
  const url = `/api/v1/codes/${codeId}/users`;
  const response = await api.get<ICodeUserResponse>(url);

  return response.data;
}

export async function removeSharedUser(codeId: ICode['id'], userId: IUser['id']): Promise<void> {
  const url = `/api/v1/codes/${codeId}/users/${userId}`;
  await api.delete(url);
}

export async function updateSharedUserAccess({
  codeId,
  userId,
  access,
}: updateUserAccessConfig): Promise<UpdatedSharedUserAccessResponse> {
  const url = `/api/v1/codes/${codeId}/users/${userId}`;
  const response = await api.patch<UpdatedSharedUserAccessResponse>(url, { access });

  return response.data;
}

export async function postInvitationAction(
  codeId: ICode['id'],
  data: invitationActionData
): Promise<void> {
  const url = `/api/v1/codes/${codeId}/users/invitation`;

  await api.post<{ message: 'success' }>(url, data);
}

export async function declineInvitationByToken(token: string): Promise<void> {
  const url = `api/v1/codes/users/invitation/${token}`;

  await api.delete(url);
}
