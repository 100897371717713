import {
  IDocument,
  IGeneralNoteAttachment,
  ISelectionAttachment,
  ISelectionCoordinates,
} from '@codex/shared';

import api from './index';

export type UpdateDocumentData = Partial<Pick<IDocument, 'title' | 'order'>>;

export async function createDocument(
  codeId: number,
  title: string,
  uuid: string,
  id?: number
): Promise<IDocument> {
  const url = '/api/v1/documents';
  const response = await api.post(url, {
    codeId,
    title,
    uuid,
    id,
  });

  return response.data;
}

export async function deleteDocument(documentId: number): Promise<void> {
  const url = `/api/v1/documents/${documentId}`;
  await api.delete(url);
}

export async function updateDocument(
  documentId: number,
  data: UpdateDocumentData
): Promise<IDocument> {
  const url = `/api/v1/documents/${documentId}`;
  const response = await api.patch<IDocument>(url, data);

  return response.data;
}

export interface SearchResponse {
  data: ISelectionCoordinates[];
  total: number;
}

export async function search(documentId: number, text: string): Promise<SearchResponse> {
  const url = `/api/v1/documents/${documentId}/search`;
  const response = await api.get(url, { params: { text } });

  return response.data;
}

export interface DocumentVersionsResponse {
  data: IDocument[];
  total: number;
}

export async function getDocumentVersions(documentId: number): Promise<DocumentVersionsResponse> {
  const url = `/api/v1/documents/${documentId}/versions`;
  const response = await api.get(url);

  return response.data;
}

export async function download(
  documentId: number,
  withAnnotations: boolean,
  attachmentsIds: (ISelectionAttachment | IGeneralNoteAttachment)['id'][]
): Promise<Blob> {
  const url = `/api/v1/documents/${documentId}/download`;
  const response = await api.post(
    url,
    {
      withAnnotations,
      attachmentsIds,
    },
    {
      responseType: 'blob',
    }
  );

  return response.data;
}
