import { CodeAccess } from '@codex/shared';
import { Values } from './types';

export enum CodeAccessProps {
  CAN_READ = 'canRead',
  CAN_ANNOTATE = 'canAnnotate',
  CAN_ORGANIZE = 'canOrganize',
  CAN_OWN = 'canOwn',
}

const ReadPermissions = [CodeAccessProps.CAN_READ];
const AnnotatePermissions = [...ReadPermissions, CodeAccessProps.CAN_ANNOTATE];
const OrganizePermissions = [...AnnotatePermissions, CodeAccessProps.CAN_ORGANIZE];
const OwnerPermissions = [...OrganizePermissions, CodeAccessProps.CAN_OWN];

export const permissions = {
  [CodeAccess.READ]: ReadPermissions,
  [CodeAccess.ANNOTATE]: AnnotatePermissions,
  [CodeAccess.ORGANIZE]: OrganizePermissions,
  [CodeAccess.OWNER]: OwnerPermissions,
};

export const initialPermissionState = {
  [CodeAccessProps.CAN_READ]: false,
  [CodeAccessProps.CAN_ANNOTATE]: false,
  [CodeAccessProps.CAN_ORGANIZE]: false,
  [CodeAccessProps.CAN_OWN]: false,
};

export enum Step {
  SIGN_UP,
  ALMOST_THERE,
}

export const INITIAL_VALUES: Values = {
  email: '',
  password1: '',
  password2: '',
  hasAcceptedTerms: false,
  firstName: '',
  lastName: '',
  country: null,
  fieldsOfLaw: [],
  position: null,
  otherPosition: '',
};

export const DEBUGG_INITIAL_VALUES: Values = {
  email: 'test@o2.pl',
  password1: '441122',
  password2: '441122',
  hasAcceptedTerms: true,
  firstName: 'Patryk',
  lastName: 'Gruszka',
  country: { value: 'POL', label: 'Poland' },
  fieldsOfLaw: [{ value: '1', label: 'test' }],
  position: { value: 'Lawyer', label: 'Lawyer' },
  otherPosition: '',
};
