import { Highlight } from './types';
import { SelectionColor } from '@codex/shared';

export const calculateNodeOffset = (element: HTMLSpanElement, node: Node) => {
  const nodeIndex = Array.prototype.indexOf.call(element.childNodes, node);
  let offset = 0;

  if (nodeIndex > 0) {
    for (let i = 0; i < nodeIndex; i++) {
      const textContent = element.childNodes[i].textContent;

      if (textContent) {
        offset += textContent.length;
      }
    }
  }

  return offset;
};

export const clearHighlights = (
  highlights: Highlight[],
  textContentItemsStr: String[],
  textDivs: HTMLSpanElement[]
) => {
  let clearedUntilDivIdx = -1;

  for (let i = 0, ii = highlights.length; i < ii; i++) {
    const match = highlights[i];
    const begin = Math.max(clearedUntilDivIdx, match.startElement);

    for (let n = begin, end = match.endElement; n <= end; n++) {
      const div = textDivs[n];

      if (!div) continue;

      div.textContent = textContentItemsStr[n] as string;
      div.className = '';
      delete div.dataset.id;
    }

    clearedUntilDivIdx = match.endElement + 1;
  }
};

export const renderHighlights = (
  highlights: Highlight[],
  textContentItemsStr: String[],
  textDivs: HTMLSpanElement[],
  activeHighlightId?: number | null
) => {
  if (!highlights.length) {
    return;
  }

  let prevEnd: any = null;

  const appendTextToDiv = (
    divIdx: number,
    fromOffset: number,
    toOffset?: number,
    className?: string,
    id?: number,
    customColor?: string
  ) => {
    const div = textDivs[divIdx];

    if (!div) return;

    const content = textContentItemsStr[divIdx].substring(fromOffset, toOffset);
    const node = document.createTextNode(content);

    if (className) {
      const span = document.createElement('span');

      if (customColor) span.style.backgroundColor = customColor;
      span.className = className;
      span.dataset.id = `${id}`;
      span.appendChild(node);
      div.appendChild(span);

      return;
    }

    div.appendChild(node);
  };

  const beginText = (
    divIdx: number,
    toOffset: number,
    className?: string,
    id?: number,
    customColor?: string
  ) => {
    if (textDivs[divIdx]) {
      textDivs[divIdx].textContent = '';
    }
    appendTextToDiv(divIdx, 0, toOffset, className, id, customColor);
  };

  highlights.forEach(
    ({ startElement, startCharacter, endElement, endCharacter, color = '', id }) => {
      const isActive = id === activeHighlightId;
      const shouldUseCustomColor = !Object.values(SelectionColor).includes(color as SelectionColor);
      const customColor = shouldUseCustomColor ? color : undefined;
      const className = `highlight ${color} ${isActive ? 'active' : ''} ${
        shouldUseCustomColor ? 'custom' : ''
      }`;

      // Match inside new div.
      if (!prevEnd || startElement !== prevEnd.endElement) {
        // If there was a previous div, then add the text at the end.
        if (prevEnd !== null) {
          appendTextToDiv(prevEnd.endElement, prevEnd.endCharacter);
        }

        // Clear the divs and set the content until the starting point.
        beginText(startElement, startCharacter);
      } else {
        appendTextToDiv(prevEnd.endElement, prevEnd.endCharacter, startCharacter, customColor);
      }

      if (startElement === endElement) {
        appendTextToDiv(
          startElement,
          startCharacter,
          endCharacter,
          `begin end ${className}`,
          id,
          customColor
        );
      } else {
        appendTextToDiv(
          startElement,
          startCharacter,
          undefined,
          `begin ${className}`,
          id,
          customColor
        );

        for (let n0 = startElement + 1, n1 = endElement; n0 < n1; n0++) {
          beginText(n0, 0, undefined, undefined, customColor);
          appendTextToDiv(n0, 0, undefined, `middle ${className}`, id, customColor);
        }

        beginText(endElement, endCharacter, `end ${className}`, id, customColor);
      }

      prevEnd = {
        endElement,
        endCharacter,
      };
    }
  );

  if (prevEnd) {
    appendTextToDiv(prevEnd.endElement, prevEnd.endCharacter);
  }
};

/**
 * Wrap every text node in a given container with a div.
 * This function prevents flickering in selection.
 * @param DocumentFragment TextLayer container
 */
export function wrapNodesWithDiv(fragment: DocumentFragment) {
  const children = Array.from(fragment.children) as HTMLElement[];

  children.forEach((el) => {
    const newDiv = document.createElement('div');

    fragment.appendChild(newDiv);
    newDiv.appendChild(el);
  });

  return fragment;
}
