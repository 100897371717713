import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICode, SocketEventType, SocketEventAction } from '@codex/shared/interfaces';
import { IUpdateCodeAccessResponse } from '@codex/shared/interfaces/endpoints/codes/users/updateCodeAccess';

import { createWeboscketListener } from 'features/wsConnection/utils';
import { WebsocketCodeActions } from 'features/wsConnection/types';
import * as CodesAPI from 'api/codes';
import { AppThunk } from 'store';
import { logout } from '../auth/authSlice';

interface CodeEditDetailsState {
  data: ICode | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: CodeEditDetailsState = {
  data: null,
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'codeEditDetails',
  initialState,
  reducers: {
    setCodeStart(state) {
      state.isLoading = true;
    },
    setCodeSuccess(state, { payload }: PayloadAction<ICode>) {
      state.data = payload;
      state.isLoading = false;
    },
    setCodeFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    resetEditCodeDetails() {
      return { ...initialState };
    },
  },
  extraReducers: {
    [logout.toString()]: () => {
      return { ...initialState };
    },
    [WebsocketCodeActions.MESSAGE]: createWeboscketListener<CodeEditDetailsState, any>({
      [SocketEventType.ACCESS]: {
        [SocketEventAction.UPDATE]: (
          { data }: CodeEditDetailsState,
          { payload }: PayloadAction<IUpdateCodeAccessResponse>
        ) => {
          if (data) {
            if (data.user.id === payload.id) data.user.access = payload.access;
          }
        },
        [SocketEventAction.DELETE]: (
          { data }: CodeEditDetailsState,
          { payload }: PayloadAction<{ id: number }>
        ) => {
          if (data) {
            if (data.user.id === payload.id) data = null;
          }
        },
      },
    }),
  },
});

export const { setCodeStart, setCodeSuccess, setCodeFailure, resetEditCodeDetails } = slice.actions;

export default slice.reducer;

export const getCode = (codeId: number): AppThunk => async (dispatch) => {
  const { setCodeStart, setCodeSuccess, setCodeFailure } = slice.actions;

  try {
    dispatch(setCodeStart());
    const code = await CodesAPI.getCode(codeId);
    dispatch(setCodeSuccess(code));
  } catch (error) {
    dispatch(setCodeFailure('Code does not exist'));
  }
};

export const saveCode = (codeId: number, data: Partial<ICode>): AppThunk => async (dispatch) => {
  try {
    await CodesAPI.updateCode(codeId, data);
  } catch (error) {
    console.warn(error);
  }
};
