export enum SocketEventType {
  PING = 'PING',
  ERROR = 'ERROR',
  NOTIFICATION = 'NOTIFICATION',
  SELECTION = 'SELECTION',
  BOOKMARK = 'BOOKMARK',
  DOCUMENT = 'DOCUMENT',
  SELECTION_ATTACHMENT = 'SELECTION_ATTACHMENT',
  SELECTION_COMMENT = 'SELECTION_COMMENT',
  NOTE = 'NOTE',
  NOTE_ATTACHMENT = 'NOTE_ATTACHMENT',
  USER = 'USER',
  ACCESS = 'ACCESS',
  USER_TOKEN_INVALIDATE = 'USER_TOKEN_INVALIDATE',
  CODE_COLORS = 'CODE_COLORS',
}

export enum SocketEventAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  JOIN = 'JOIN',
  LEAVE = 'LEAVE',
}

export interface ISocketEvent<T = Object> {
  type: SocketEventType;
  action: SocketEventAction;
  data: T;
}
