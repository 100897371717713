import React, { memo } from 'react';

import MobileIcon from 'assets/mobile.svg';
import Button, { Color as ButtonColor, Size as ButtonSize } from 'components/Button';
import Modal from 'components/Modal/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import ModalTitle from 'components/Modal/ModalTitle';
import { Header, IconWrapper, Content, Title, Subtitle, Footer } from './MobileWarningModal.styled';

interface MobileWarningModalProps {
  onClose: () => void;
}

const MobileWarningModal = ({
  onClose,
}: MobileWarningModalProps) => {
  return (
    <Modal hideForDesktop isOpen onClose={onClose} width={328}>
      <ModalHeader onCloseClick={onClose}>
        <Header>
          <ModalTitle>Desktop only</ModalTitle>
        </Header>
      </ModalHeader>
      <Content>
          <IconWrapper>
            <img src={MobileIcon} alt='Mobile warning' />
          </IconWrapper>
          <Title>This view is currently not supported on mobile devices.</Title>
          <Subtitle>We highly recommend using a desktop machine.</Subtitle>
      </Content>
      <Footer>
          <Button
            fullWidth
            color={ButtonColor.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={onClose}
          >
            I understand
          </Button>
        </Footer>
    </Modal>
  );
};

export default memo(MobileWarningModal);
