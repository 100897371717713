import { ISocketEvent, SocketEventType, SocketEventAction } from '@codex/shared/interfaces';
import { PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';

type HandlerFunction<State, Payload> = (state: State, action: PayloadAction<Payload>) => void;

type Handlers<State, Payload> = {
  [key in SocketEventType]?: {
    [key in SocketEventAction]?: HandlerFunction<State, Payload>;
  };
};

export const createWeboscketListener = <State, Payload>(handlers: Handlers<State, Payload>) => (
  state: State,
  { payload }: PayloadAction<{ message: string }>
) => {
  const { type, action, data }: ISocketEvent<Payload> = JSON.parse(payload.message);
  const functionHandler: HandlerFunction<State, Payload> = get(handlers, [type, action]);

  if (functionHandler) {
    functionHandler(state, { payload: data, type });
  }

  return state;
};
