import { ICode, ICodeChanges, IOrderBy, ReviewCodeUpdate } from '@codex/shared/interfaces';

import api from './index';

export interface CodesResponse {
  data: ICode[];
}

export interface CodesChangesResponse {
  data: ICodeChanges;
  total: number;
}

interface CopyCodeParams {
  codeId: number, 
  title: string, 
  withAnnotations?: boolean, 
  allowUpdates?: boolean, 
}

export async function getCodes(params: any | IOrderBy): Promise<CodesResponse> {
  const url = '/api/v1/codes';
  const response = await api.get<CodesResponse>(url, { params });

  return response.data;
}

export async function getCode(codeId: number): Promise<ICode> {
  const url = `/api/v1/codes/${codeId}`;
  const response = await api.get<ICode>(url);

  return response.data;
}

export async function getCodeWithUpdates(codeId: number): Promise<ICode> {
  const url = `/api/v1/codes/${codeId}?showUpdates=true`;
  const response = await api.get<ICode>(url);

  return response.data;
}

export async function createCode(): Promise<ICode> {
  const url = '/api/v1/codes';
  const response = await api.post<ICode>(url);

  return response.data;
}

export async function deleteCode(codeId: number): Promise<ICode> {
  const url = `/api/v1/codes/${codeId}`;
  const response = await api.delete(url);

  return response.data;
}

export async function updateCode(codeId: number, data: Partial<ICode>): Promise<ICode> {
  const url = `/api/v1/codes/${codeId}`;
  const response = await api.patch<ICode>(url, data);

  return response.data;
}

export async function copyCode({ 
  codeId, 
  title, 
  withAnnotations = false, 
  allowUpdates = false 
}: CopyCodeParams): Promise<ICode> {
  const url = `/api/v1/codes/copy/${codeId}`;
  const response = await api.post<ICode>(url, { title, withAnnotations, allowUpdates });

  return response.data;
}

export async function reviewUpdate(codeId: number, reviewResult: ReviewCodeUpdate): Promise<ICode> {
  const url = `/api/v1/codes/${codeId}/review`;
  const response = await api.post<ICode>(url, { reviewResult });

  return response.data;
}

export async function addNewColor(codeId: number, color: string): Promise<any> {
  const url = `/api/v1/codes/${codeId}/colors`;
  const response = await api.put<ICode>(url, { color });

  return response.data;
}

export async function getCodeChanges(codeId: number): Promise<CodesChangesResponse> {
  const url = `/api/v1/codes/${codeId}/changes`;
  const response = await api.get<CodesChangesResponse>(url);

  return response.data;
}
