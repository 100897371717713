import { debounce, DebounceSettings } from 'lodash';

import { AppDispatch } from 'store';

export default function debounceAction(action: any, wait: number, options?: DebounceSettings) {
  const debounced = debounce(
    (dispatch: AppDispatch, actionArgs: any[]) => dispatch(action(...actionArgs)),
    wait,
    options
  );

  const thunk = (...actionArgs: any[]) => (dispatch: AppDispatch) =>
    debounced(dispatch, actionArgs);

  thunk.cancel = debounced.cancel;
  thunk.flush = debounced.flush;

  return thunk;
}
