import { createGlobalStyle, css } from 'styled-components/macro';

const fontFace = (
  fontFamily: string,
  src: string,
  fontWeight: number,
  fontStyle = 'normal',
  version?: string
) => css`
  @font-face {
    font-family: '${fontFamily}';
    src: url('/fonts/${src}.woff2${version}') format('woff2'),
      url('/fonts/${src}.woff${version}') format('woff'),
      url('/fonts/${src}.ttf${version}') format('truetype');
    font-weight: ${fontWeight};
    font-style: ${fontStyle};
  }
`;

const FontsGlobalStyles = createGlobalStyle`
  ${fontFace('Icons', 'Icons', 500, 'normal', '?v=4')};
  ${fontFace('Gilroy', 'Gilroy-Light', 300)};
  ${fontFace('Gilroy', 'Gilroy-LightItalic', 300, 'italic')};
  ${fontFace('Gilroy', 'Gilroy-Regular', 400)};
  ${fontFace('Gilroy', 'Gilroy-RegularItalic', 400, 'italic')};
  ${fontFace('Gilroy', 'Gilroy-Medium', 500)};
  ${fontFace('Gilroy', 'Gilroy-MediumItalic', 500, 'italic')};
  ${fontFace('Gilroy', 'Gilroy-SemiBold', 600)};
  ${fontFace('Gilroy', 'Gilroy-SemiBoldItalic', 600, 'italic')};
  ${fontFace('Gilroy', 'Gilroy-Bold', 700)};
  ${fontFace('Gilroy', 'Gilroy-BoldItalic', 700, 'italic')};
`;

export default FontsGlobalStyles;
