import ReactModal from 'react-modal';
import styled from 'styled-components/macro';

export const Wrapper = styled(ReactModal)`
  outline: none;
  padding: 24px;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
`;

export const Inner = styled.div<{ width?: number, responsive?: boolean }>`
  width: ${({ responsive, width }) => responsive ? '100%' : `${width}px`};
  
  border-radius: 4px;
  background-color: var(--color-white);
  overflow: hidden;
`;

Inner.defaultProps = {
  width: 675,
  responsive: false,
};
