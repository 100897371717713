import axios, { AxiosRequestConfig } from 'axios';
import { IFileSignRequest, FileContentType } from '@codex/shared';
import api from './index';

export interface SignUrlResponse {
  url: string;
  uuid: string;
}

export async function getSignedUrl(data: IFileSignRequest): Promise<SignUrlResponse> {
  const url = '/api/v1/files/sign';
  const response = await api.post<SignUrlResponse>(url, data);

  return response.data;
}

export async function upload(
  file: File,
  contentType: FileContentType,
  config?: AxiosRequestConfig
): Promise<string> {
  const { url, uuid } = await getSignedUrl({ contentType });
  try {
    await axios.put(url, file, {
      headers: {
        'Content-Type': contentType,
      },
      ...config,
    });

    return uuid;
  } catch (error) {
    throw error;
  }
}

export type FileType = 'selection-attachments' | 'note-attachments' | 'documents';

const fileTypeUrlMap = {
  'selection-attachments': (fileId: number) => `/api/v1/selections/attachments/${fileId}/file`,
  'note-attachments': (fileId: number) => `/api/v1/notes/attachments/${fileId}/file`,
  documents: (fileId: number, showUpdates?: boolean) => `/api/v1/documents/${fileId}/file${ showUpdates ? '?showUpdates=true' : '' }`,
};

export async function fetchFile(fileType: FileType, fileId: number) {
  const url = fileTypeUrlMap[fileType](fileId);
  const response = await api.get(url, { responseType: 'blob' });

  return response.data;
}

export function buildFileUrl(fileType: FileType, fileId: number, showUpdates?: boolean) {
  return `${process.env.REACT_APP_API_URL ?? ''}${fileTypeUrlMap[fileType](fileId, showUpdates)}`;
}
