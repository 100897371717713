import { createGlobalStyle } from 'styled-components/macro';

const CoreGlobalStyles = createGlobalStyle`
  :root {
    --color-main-light: #665d89;
    --color-main: #7f08fb;
    --color-main-hover: #7f08dd;
    --color-main-active: #7f08bf;
    --color-main-text: #7f00ff;
    --color-main-dark: #100146;
    
    --color-selection: #accef7;
    
    --color-info: #007dfd;
    --color-success: #23c753;
    --color-danger: #ff3226;
    --color-warning: #ff9000;
    
    --color-blue: #03b0fc;
    
    --color-white: #fff;
    --color-gray-50: #fbfcfe;
    --color-gray-100: #fbf7fe;
    --color-gray-200: #f6f4ff;
    --color-gray-300: #f7f8fa;
    --color-gray-400: #ebecef;
    --color-gray-500: #d3d3d8;
    --color-gray-600: #d8d8d8;
    --color-gray-700: #c9c9d0;
    --color-gray-800: #c6c2d3;
    --color-gray-900: #b0b0b6;
    --color-gray-1000: #aeaeae;
    --color-gray-1100: #8f9099;
    --color-gray-1200: #64656c;
    --color-gray-1300: #494a50;
    --color-gray-1400: #3b3c42;
    --color-gray-1500: #2d2e32;
    --color-gray-1600: #1a1b1e;
    --color-black: #000;
    
    --border-common: 1px solid var(--color-gray-400);
  }
`;

export default CoreGlobalStyles;
