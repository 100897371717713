import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { Icon } from 'components/Icons';
import { ButtonProps, LinkProps, isLink } from './types';
import { SIZE_TO_HEIGHT, SIZE_TO_PADDING, Size, Color } from './constants';

const PrimaryColor = css`
  border: 0;
  background-color: var(--color-main);
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
  color: var(--color-white);
  font-weight: 600;

  &:hover {
    background-color: var(--color-main-hover);
  }

  &:active,
  &:focus {
    background-color: var(--color-main-active);
  }

  &[disabled] {
    background-color: var(--color-gray-500);
  }

  ${Icon} {
    color: var(--color-white);
  }
`;

const SecondaryColor = css`
  background-color: var(--color-white);
  border: var(--border-common);
  color: var(--color-gray-1300);
  font-weight: 500;

  &:hover {
    background-color: var(--color-gray-300);
  }

  &:active,
  &:focus {
    background-color: var(--color-gray-400);
  }

  &[disabled] {
    background-color: var(--color-white);
    border-color: var(--color-gray-400);
    color: var(--color-gray-900);

    ${Icon} {
      color: var(--color-gray-900);
    }
  }
`;

const DangerColor = css`
  border: 0;
  background-color: var(--color-danger);
  box-shadow: inset 0 2px 0 0 rgba(255, 255, 255, 0.08);
  color: var(--color-white);
  font-weight: 600;

  &:hover {
    background-color: #ff3b30;
  }

  &:active,
  &:focus {
    background-color: #ee4135;
  }

  &[disabled] {
    background-color: var(--color-gray-500);
  }

  ${Icon} {
    color: var(--color-white);
  }
`;

const TransparentColor = css`
  border: 0;
  background-color: transparent;
  color: var(--color-gray-1600);
  font-weight: 500;

  &:active,
  &:focus {
    background-color: transparent;
  }

  &[disabled] {
    background-color: transparent;
  }

  ${Icon} {
    color: var(--color-gray-1600);
  }
`;

const BaseButton = (props: ButtonProps | LinkProps) => {
  const { color, size, hasMarginLeft, hasMarginRight, ...rest } = props;

  if (isLink(props)) {
    return <Link {...(rest as LinkProps)} />;
  } else {
    return <button type="button" {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)} />;
  }
};

const Button = styled(BaseButton)<ButtonProps | LinkProps>`
  padding: ${({ size }) => SIZE_TO_PADDING[size!]};
  min-height: ${({ size }) => SIZE_TO_HEIGHT[size!]};
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  line-height: 1.33;

  &[disabled] {
    cursor: not-allowed;
  }

  ${({ color }) => {
    switch (color) {
      case Color.PRIMARY:
        return PrimaryColor;
      case Color.SECONDARY:
        return SecondaryColor;
      case Color.DANGER:
        return DangerColor;
      case Color.TRANSPARENT:
        return TransparentColor;
    }
  }};

  ${({ hasMarginRight }) =>
    hasMarginRight &&
    css`
      margin-right: 8px;
    `};

  ${({ hasMarginLeft }) =>
    hasMarginLeft &&
    css`
      margin-left: 8px;
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      text-align: center;
      display: block;
    `};
`;

Button.defaultProps = {
  color: Color.PRIMARY,
  size: Size.MEDIUM,
  fullWidth: false,
};

export default Button;
