import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import includes from 'lodash/includes';
import { CodeAccess } from '@codex/shared';

import { logout as logoutAction, getProfile } from './authSlice';
import { getUser } from './selectors';
import { getCode } from 'features/codeEdit/selectors';
import { permissions, initialPermissionState, CodeAccessProps } from './constants';
import { clearDocumentsPositions } from '../codeEdit/utils';

export const useUser = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const logout = useCallback(() => {
    dispatch(logoutAction());
    clearDocumentsPositions();
  }, [dispatch]);

  return [user, logout] as const;
};

export const useFetchProfile = () => {
  const [user] = useUser();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isViewProfileRoute = matchPath(pathname, { path: '/account/profile', exact: true });
  const [profileUpdated, setProfileUpdated] = useState(false);
  const isLoggedIn = !!user;

  useEffect(() => {
    setProfileUpdated(true);

    if (!isLoggedIn || isViewProfileRoute || profileUpdated) return;

    dispatch(getProfile());
  }, [dispatch, isLoggedIn, isViewProfileRoute, profileUpdated]);
};

export const useLogoutOnStorageChange = () => {
  const [, logout] = useUser();

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key !== 'persist:auth' || !event.oldValue || !event.newValue) return;

      const oldValue = JSON.parse(event.oldValue);
      const newValue = JSON.parse(event.newValue);
      const oldToken = JSON.parse(oldValue.token);
      const newToken = JSON.parse(newValue.token);

      if (newToken !== oldToken && newToken === null) logout();
    };

    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, [logout]);
};

type useIsAuthorizedParams = {
  access?: CodeAccess | null;
  ownerId?: number | null;
};

type useIsAuthorizedReturn = {
  [key in CodeAccessProps]: boolean;
};

export const useIsAuthorized = ({
  access,
  ownerId,
}: useIsAuthorizedParams = {}): useIsAuthorizedReturn => {
  const [user] = useUser();
  const codeEdit = useSelector(getCode);
  const accessTypePermissions = access
    ? permissions[access]
    : codeEdit && permissions[codeEdit.user.access];
  const permissionState = useMemo(
    () =>
      Object.values(CodeAccessProps).reduce(
        (acc, codeAccessProp) => ({
          ...acc,
          [codeAccessProp]: includes(accessTypePermissions, codeAccessProp),
        }),
        initialPermissionState
      ),
    [accessTypePermissions]
  );

  if (!accessTypePermissions) return initialPermissionState;

  if (ownerId && user) {
    if (ownerId === user.id) return permissionState;
    if (ownerId !== user.id) return initialPermissionState;
  }

  return permissionState;
};
