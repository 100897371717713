import axios from 'axios';

import { logout, setError } from 'features/auth/authSlice';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  (config) => {
    const store = require('store').default;
    const {
      auth: { token },
    } = store.getState();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const store = require('store').default;
    const {
      auth: { token },
    } = store.getState();

    if (error?.response.status === 401 && token) {
      console.log(error);
      store.dispatch(logout());
      store.dispatch(setError("You've been logged out"));

      import('features/codeEdit/utils').then((m) => {
        m.clearDocumentsPositions();
      });
    }

    return Promise.reject(error);
  }
);

export default api;
