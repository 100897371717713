export enum WebsocketCodeActions {
  MESSAGE = 'code::MESSAGE',
  OPEN = 'code::OPEN',
  CONNECT = 'code::CONNECT',
  CLOSED = 'code::CLOSED',
  ERROR = 'code::ERROR',
  SEND = 'code::SEND',
  DISCONNECT = 'code::DISCONNECT',
}

export enum WebsocketUserActions {
  MESSAGE = 'user::MESSAGE',
  OPEN = 'user::OPEN',
  CONNECT = 'user::CONNECT',
  CLOSED = 'user::CLOSED',
  ERROR = 'user::ERROR',
  SEND = 'user::SEND',
  DISCONNECT = 'user::DISCONNECT',
}
