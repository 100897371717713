import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { IUser } from '@codex/shared';

import * as ProfileAPI from 'api/profile';
import * as AuthAPI from 'api/auth';
import { AppThunk } from 'store';

interface AuthState {
  user: IUser | null;
  token: string | null;
  twoFactor:
    | { device2faToken: string; qrUrl: string }
    | { device2faToken: undefined; qrUrl: undefined };
  error: string | null;
  cookieBanner: boolean | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  twoFactor: { device2faToken: undefined, qrUrl: undefined },
  error: null,
  cookieBanner: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, { payload: { user, token } }: PayloadAction<AuthAPI.UserResponse>) {
      state.user = user;
      state.token = token;
      state.error = null;
    },
    logout({ twoFactor }) {
      return { ...initialState, twoFactor };
    },
    updateUser(state, { payload }: PayloadAction<Partial<IUser>>) {
      Object.assign(state.user, payload);
    },
    set2faQrUrl({ twoFactor }, { payload }: PayloadAction<string>) {
      twoFactor.qrUrl = payload;
    },
    set2faUser({ user }, { payload }: PayloadAction<boolean>) {
      if (user) user.has2fa = payload;
    },
    setDevice2faToken({ twoFactor }, { payload }: PayloadAction<string>) {
      twoFactor.device2faToken = payload;
    },
    setCookieBanner(state, { payload }: PayloadAction<boolean>) {
      state.cookieBanner = payload;
    },
    setError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    resetAuth() {
      return { ...initialState };
    },
  },
});

const persistConfig = {
  key: 'auth',
  version: 1,
  whitelist: ['user', 'token', 'twoFactor', 'cookieBanner'],
  storage,
};

export const {
  logout,
  loginSuccess,
  resetAuth,
  setError,
  set2faQrUrl,
  setDevice2faToken,
  set2faUser,
  setCookieBanner,
} = slice.actions;

export default persistReducer(persistConfig, slice.reducer);

export const login = (data: {
  email: string;
  password: string;
  token?: string;
  device2faToken?: string;
}): AppThunk => async (dispatch) => {
  const response = await AuthAPI.login(data);

  if (response.device2faToken) dispatch(setDevice2faToken(response.device2faToken));
  dispatch(loginSuccess(response));
};

export const getProfile = (): AppThunk => async (dispatch) => {
  const { updateUser } = slice.actions;
  const profile = await ProfileAPI.getProfile();

  dispatch(updateUser(profile));
};

export const updateProfile = (data: Partial<IUser>): AppThunk => async (dispatch) => {
  const { updateUser } = slice.actions;
  const profile = await ProfileAPI.updateProfile(data);

  dispatch(updateUser(profile));
};

export const deleteProfile = (): AppThunk => async (dispatch) => {
  await ProfileAPI.deleteProfile();
  dispatch(logout());
};

export const generateTwoFactorQr = (userId: number): AppThunk => async (dispatch) => {
  const { qrCodeUrl } = await ProfileAPI.generateTwoFactorQrUrl({ userId });

  dispatch(set2faQrUrl(qrCodeUrl));
};

export const activateTwoFactor = (token: string): AppThunk => async (dispatch) => {
  await ProfileAPI.toggleTwoFactorActivation({ token, activate: true });

  dispatch(set2faUser(true));
};

export const disableTwoFactor = (token: string): AppThunk => async (dispatch) => {
  await ProfileAPI.toggleTwoFactorActivation({ token, activate: false });

  dispatch(set2faUser(false));
};
