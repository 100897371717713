import styled from 'styled-components/macro';

export const Header = styled.header`
  display: flex;
`;

export const Content = styled.footer`
  background-color: var(--color-gray-300);
  height: 220px;
  padding: 32px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-warning);
  width: 40px;
  height: 40px;
  border-radius: 50%;
`

export const Title = styled.h2`
  margin-top: 18px;
  text-align: center;
  line-height: 25px;
  font-weight: 600;
  color: var(--color-gray-1300);
  font-size: 15px;
`;

export const Subtitle = styled.p`
   text-align: center;
   line-height: 25px;
   color: var(--color-gray-1300);
   font-size: 15px;
`;


export const Footer = styled.footer`
  background-color: var(--color-white);
  min-height: 88px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: var(--border-common);
`;

