import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useUser } from 'features/auth/hooks';
import { getError } from 'features/auth/selectors';
import { WarningInfoIcon } from 'components/Icons';

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const [user] = useUser();
  const errorMessage = useSelector(getError);
  const isLoggedIn = !!user;

  useEffect(() => {
    if (errorMessage) {
      toast(
        <>
          <WarningInfoIcon hasMarginRight size={19} />
          <div>{errorMessage}</div>
        </>
      );
    }
  }, [errorMessage]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
