import React from 'react';

import CoreGlobalStyles from './CoreGlobalStyles';
import FontsGlobalStyles from './FontsGlobalStyles';
import VariablesGlobalStyles from './VariablesGlobalStyles';
import ReactourGlobalStyles from './ReactourGlobalStyles';
import ModalGlobalStyles from 'components/Modal/ModalGlobalStyles';
import ToastGlobalStyles from 'components/Toast/ToastGlobalStyles';

const GlobalStyles = () => (
  <>
    <CoreGlobalStyles />
    <FontsGlobalStyles />
    <VariablesGlobalStyles />
    <ReactourGlobalStyles />
    <ModalGlobalStyles />
    <ToastGlobalStyles />
  </>
);

export default GlobalStyles;
