import englishMessages from 'lang/en.json';
import frenchMessages from 'lang/fr.json';
import germanMessages from 'lang/de.json';
import italianMessages from 'lang/it.json';
import spanishMessages from 'lang/es.json';

import { Messages } from './types';

export enum Locale {
  English = 'en',
  French = 'fr',
  German = 'de',
  Italian = 'it',
  Spanish = 'es',
}

export const languages = [
  {
    locale: Locale.English,
    name: 'English',
  },
  {
    locale: Locale.German,
    name: 'Deutsch',
  },
  {
    locale: Locale.French,
    name: 'Français',
  },
  {
    locale: Locale.Italian,
    name: 'Italiano',
  },
  {
    locale: Locale.Spanish,
    name: 'Español',
  },
];

export const isSupportedLocale = (localeToCheck: string | null) =>
  Boolean(languages.find(({ locale }) => locale === localeToCheck));

const messages: Record<string, Messages> = {
  [Locale.English]: englishMessages,
  [Locale.French]: frenchMessages,
  [Locale.German]: germanMessages,
  [Locale.Italian]: italianMessages,
  [Locale.Spanish]: spanishMessages,
};

export const getMessages = (locale?: string): Messages =>
  locale && isSupportedLocale(locale) ? messages[locale] || englishMessages : englishMessages;

export const getUserLocale = (): string => {
  try {
    const persistedLocale = localStorage.getItem('locale');
    if (typeof persistedLocale === 'string' && isSupportedLocale(persistedLocale)) {
      return persistedLocale;
    }

    const navigatorLocale = navigator?.language?.split(/[-_]/)[0];

    return typeof navigatorLocale === 'string' && isSupportedLocale(navigatorLocale)
      ? navigatorLocale
      : Locale.English;
  } catch {
    return Locale.English;
  }
};
