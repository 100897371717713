import { createSlice } from '@reduxjs/toolkit';

import { logout } from 'features/auth/authSlice';
import { WebsocketUserActions, WebsocketCodeActions } from './types';

interface connectionState {
  isUserConnected: boolean;
  isUserOpened: boolean;
  isCodeOpened: boolean;
  error: string | null;
}

const initialState: connectionState = {
  isUserConnected: false,
  isUserOpened: false,
  isCodeOpened: false,
  error: null,
};

const slice = createSlice({
  name: 'wsConnection',
  initialState,
  reducers: {
    connect(state) {
      state.isUserConnected = true;
    },
  },
  extraReducers: {
    [logout.toString()]: () => {
      return { ...initialState };
    },
    [WebsocketUserActions.CONNECT]: (state) => {
      state.isUserConnected = true;
    },
    [WebsocketUserActions.CLOSED]: (state) => {
      state.isUserConnected = false;
      state.isUserOpened = false;
    },
    [WebsocketUserActions.OPEN]: (state) => {
      state.isUserOpened = true;
    },
    [WebsocketCodeActions.OPEN]: (state) => {
      state.isCodeOpened = true;
    },
    [WebsocketCodeActions.CLOSED]: (state) => {
      state.isCodeOpened = false;
    },
  },
});

export const { connect } = slice.actions;

export default slice.reducer;
