import { ICodeUser, CodeInvitationAction } from '../code/ICode';

export enum NotificationType {
  CODE_INVITATION = 'CODE_INVITATION',
  CODE_INVITATION_RESPONSE = 'CODE_INVITATION_RESPONSE',
  CODE_CHANGED = 'CODE_CHANGED',
}

export type NotificationProps = Object;

export interface INotification {
  id: number;
  type: NotificationType;
  isActive: boolean;
  props: NotificationProps;
  created_at: string;
  updated_at: string;
}

interface InviterData {
  firstName: string;
  lastName: string;
  avatar: string;
}

interface CodeNotificationData {
  id: number;
  title: string;
}

export interface CodeInviteNotificationProps {
  code: CodeNotificationData;
  inviter: InviterData;
}

export interface CodeChangedNotificationProps {
  code: CodeNotificationData;
  inviter: InviterData;
  copiedCodeId?: number;
}

export interface CodeInviteNotification extends INotification {
  type: NotificationType.CODE_INVITATION;
  props: CodeInviteNotificationProps;
}

export interface CodeChangedNotification extends INotification {
  type: NotificationType.CODE_CHANGED;
  props: CodeChangedNotificationProps;
}

export interface CodeInviteResponseNotificationProps {
  invitee: Omit<ICodeUser, 'id' | 'invitationStatus' | 'access'>;
  code: CodeNotificationData;
  action: CodeInvitationAction;
}

export interface CodeInviteResponseNotification extends INotification {
  type: NotificationType.CODE_INVITATION_RESPONSE;
  props: CodeInviteResponseNotificationProps;
}

export type CodeNotificationTypes = CodeInviteResponseNotification &
  CodeInviteNotification &
  CodeChangedNotification;
