import { ISelectionCoordinates } from '../interfaces';

const is = (startValue: number) => ({
  before: (endValue: number) => startValue < endValue,
  beforeEqual: (endValue: number) => startValue <= endValue,
  equal: (endValue: number) => startValue === endValue,
  after: (endValue: number) => startValue > endValue,
  afterEqual: (endValue: number) => startValue >= endValue,
});

const startsOnExisting = (
  { startPage, startElement, startCharacter }: ISelectionCoordinates,
  {
    startPage: exStartPage,
    startElement: exStartElement,
    startCharacter: exStartCharacter,
    endPage: exEndPage,
    endElement: exEndElement,
    endCharacter: exEndCharacter,
  }: ISelectionCoordinates
) =>
  (is(startPage).afterEqual(exStartPage) && is(startPage).before(exEndPage)) ||
  (is(startPage).equal(exStartPage) &&
    is(startPage).equal(exEndPage) &&
    ((is(startElement).after(exStartElement) &&
      is(startElement).before(exEndElement)) ||
      (is(startElement).afterEqual(exStartElement) &&
        is(startElement).equal(exEndElement) &&
        is(startCharacter).afterEqual(exStartCharacter) &&
        is(startCharacter).before(exEndCharacter)) ||
      (is(startElement).equal(exStartElement) &&
        is(startElement).beforeEqual(exEndElement) &&
        is(startCharacter).afterEqual(exStartCharacter) &&
        is(startCharacter).before(exEndCharacter))));

const endsOnExisting = (
  { endPage, endElement, endCharacter }: ISelectionCoordinates,
  {
    startPage: exStartPage,
    startElement: exStartElement,
    startCharacter: exStartCharacter,
    endPage: exEndPage,
    endElement: exEndElement,
    endCharacter: exEndCharacter,
  }: ISelectionCoordinates
) =>
  (is(endPage).afterEqual(exStartPage) && is(endPage).before(exEndPage)) ||
  (is(endPage).equal(exStartPage) &&
    is(endPage).equal(exEndPage) &&
    ((is(endElement).after(exStartElement) &&
      is(endElement).before(exEndElement)) ||
      (is(endElement).afterEqual(exStartElement) &&
        is(endElement).equal(exEndElement) &&
        is(endCharacter).after(exStartCharacter) &&
        is(endCharacter).beforeEqual(exEndCharacter)) ||
      (is(endElement).equal(exStartElement) &&
        is(endElement).beforeEqual(exEndElement) &&
        is(endCharacter).after(exStartCharacter) &&
        is(endCharacter).beforeEqual(exEndCharacter))));

const startsBeforeAndEndsAfterExisting = (
  {
    startPage,
    startElement,
    startCharacter,
    endPage,
    endElement,
    endCharacter,
  }: ISelectionCoordinates,
  {
    startPage: exStartPage,
    startElement: exStartElement,
    startCharacter: exStartCharacter,
    endPage: exEndPage,
    endElement: exEndElement,
    endCharacter: exEndCharacter,
  }: ISelectionCoordinates
) =>
  (is(startPage).before(exStartPage) && is(endPage).after(exEndPage)) ||
  (is(startPage).beforeEqual(exStartPage) &&
    is(startElement).before(exStartElement) &&
    is(endPage).afterEqual(exEndPage) &&
    is(endElement).after(exEndElement)) ||
  (is(startPage).beforeEqual(exStartPage) &&
    (is(startElement).before(exStartElement) ||
      (is(startElement).equal(exStartElement) &&
        is(startCharacter).before(exStartCharacter))) &&
    is(endPage).afterEqual(exEndPage) &&
    ((is(endElement).equal(exEndElement) &&
      is(endCharacter).after(exEndCharacter)) ||
      is(endElement).after(exEndElement)));

export const isSelectionOverlapping = (
  selection: ISelectionCoordinates,
  existingSelections: ISelectionCoordinates[]
) => {
  const doesOverlap = (existingSelection: ISelectionCoordinates) =>
    startsOnExisting(selection, existingSelection) ||
    endsOnExisting(selection, existingSelection) ||
    startsBeforeAndEndsAfterExisting(selection, existingSelection);

  return existingSelections.some(doesOverlap);
};
