import React from 'react';
import { Props as ReactModalProps } from 'react-modal';

import { Wrapper, Inner } from './Modal.styled';

interface ModalProps extends ReactModalProps {
  children: React.ReactNode;
  onClose?: () => void;
  width?: number;
  responsive?: boolean;
  hideForMobile?: boolean;
  hideForDesktop?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, onClose, width, responsive, hideForMobile, hideForDesktop, ...restProps }) => (
  <Wrapper
    overlayClassName={`modal__overlay ${hideForMobile ? '--hide-for-mobile' : ''} ${hideForDesktop ? '--hide-for-desktop' : ''}`}
    shouldCloseOnEsc
    onRequestClose={onClose}
    {...restProps}
  >
    <Inner responsive={responsive} width={width}>{children}</Inner>
  </Wrapper>
);

export default Modal;
