import { ITableOfContents, IDocument } from './IDocument';
import { FieldOfLaw } from '../FieldOfLaw';
import { Country } from '../Country';
import { IUserBase, IUser } from '..';

export enum CodeCoverColor {
  COLOR_1 = 'color-1',
  COLOR_2 = 'color-2',
  COLOR_3 = 'color-3',
  COLOR_4 = 'color-4',
  COLOR_5 = 'color-5',
  COLOR_6 = 'color-6',
  COLOR_7 = 'color-7',
  COLOR_8 = 'color-8',
  COLOR_9 = 'color-9',
  COLOR_10 = 'color-10',
  COLOR_11 = 'color-11',
  COLOR_12 = 'color-12',
  COLOR_13 = 'color-13',
  COLOR_14 = 'color-14',
  COLOR_15 = 'color-15',
  COLOR_16 = 'color-16',
  COLOR_17 = 'color-17',
  COLOR_18 = 'color-18',
  COLOR_19 = 'color-19',
  COLOR_20 = 'color-20',
}

export enum CodeCoverIcon {
  NONE = 'none',
  WARNING = 'warning',
  DOCS = 'docs',
}

export enum CodeStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum CodeInvitationStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
}

export enum CodeAccess {
  READ = 'read',
  ANNOTATE = 'annotate',
  ORGANIZE = 'organize',
  OWNER = 'owner',
}

export enum ReviewCodeUpdate {
  APPROVE = 'approve',
  DECLINE = 'decline',
  COPY = 'copy',
}

export enum CodeInvitationAction {
  ACCEPT = 'accept_invitation',
  DECLINE = 'decline_invitation',
  LEAVE = 'leave_code',
}

export type ICodeUserInfo = Pick<
  IUser,
  'id' | 'avatar' | 'firstName' | 'lastName' | 'email'
>;

export interface ICodeUser
  extends Pick<
    IUser,
    'id' | 'avatar' | 'firstName' | 'lastName' | 'email' | 'type'
  > {
  access: CodeAccess;
  invitationStatus: CodeInvitationStatus;
}

export interface IBaseCode {
  id: number;
  author: IUserBase;
  created_at: string;
  updated_at: string;
  title: string;
  description: string;
  country: Country;
  coverIcon: CodeCoverIcon;
  coverColor: CodeCoverColor;
  status: CodeStatus;
  isPublic: boolean;
  fieldOfLaw: FieldOfLaw;
  isDeleted: boolean;
  documents: ITableOfContents[];
  isPublishedWithAnnotations: boolean;
  totalSelectionsCount?: number;
}

export interface ICode extends IBaseCode {
  uuid: string;
  totalSelectionsCount: number;
  documents: IDocument[];
  user: ICodeUser;
  colors: string[];
  isShared?: boolean;
  libraryId?: number;
  documentsToUpdate: number[];
  allowUpdates?: boolean;
  newCodeId?: number;
  hasAvailableUpdate?: boolean;
}

export enum CodeChangeAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface IDocumentChange { 
  action: CodeChangeAction;
  id?: number;
  payload: { title?: string; order?: number; } | IDocument;
}

export interface IDetailChange {
  title?: string;
  description?: string;
  coverColor?: CodeCoverColor;
  fieldOfLaw?: FieldOfLaw;
  country?: Country;
}
export interface ICodeChanges {
  detail?: IDetailChange;
  documents?: IDocumentChange[];

}
