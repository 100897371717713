import { LinkProps as RouterLinkProps } from 'react-router-dom';

import { Size, Color } from './constants';

interface BaseProps {
  size?: Size;
  color?: Color;
  hasMarginLeft?: boolean;
  hasMarginRight?: boolean;
  fullWidth?: boolean;
}

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & BaseProps;
export type LinkProps = RouterLinkProps & BaseProps;
export const isLink = (props: ButtonProps | LinkProps): props is LinkProps => 'to' in props;
