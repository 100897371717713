import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CodeNotificationTypes,
  SocketEventType,
  SocketEventAction,
} from '@codex/shared/interfaces';
import { merge, filter } from 'lodash';

import { WebsocketUserActions } from 'features/wsConnection/types';
import * as NotificationsAPI from 'api/notifications';
import { AppThunk } from 'store';
import { logout } from 'features/auth/authSlice';
import { createWeboscketListener } from 'features/wsConnection/utils';

interface NotificationState {
  data: CodeNotificationTypes[];
  isLoading: boolean;
  error: string | null;
}

const initialState: NotificationState = {
  data: [],
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    fetchNotificationsSuccess(state, { payload }: PayloadAction<CodeNotificationTypes[]>) {
      state.data = payload;
      state.isLoading = false;
    },
    deleteNotificationSuccess(
      state,
      { payload }: PayloadAction<CodeNotificationTypes['id']>
    ) {
      const updatedNotifications = filter(
        state.data,
        (notification) => notification.id !== payload
      );

      state.data = updatedNotifications;
      state.isLoading = false;
    },
    updateNotificationsSuccess(state, { payload }: PayloadAction<CodeNotificationTypes[]>) {
      const updatedNotifications = merge(state.data, payload);

      state.data = updatedNotifications;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [logout.toString()]: () => {
      return { ...initialState };
    },
    [WebsocketUserActions.MESSAGE]: createWeboscketListener<
      NotificationState,
      CodeNotificationTypes
    >({
      [SocketEventType.NOTIFICATION]: {
        [SocketEventAction.CREATE]: (
          state: NotificationState,
          { payload }: PayloadAction<CodeNotificationTypes>
        ) => {
          state.data = [payload, ...state.data];
        },
      },
    }),
  },
});

export const {
  setLoading,
  setFailure,
  fetchNotificationsSuccess,
  deleteNotificationSuccess,
  updateNotificationsSuccess,
} = slice.actions;

export default slice.reducer;

export const fetchNotifications = (): AppThunk => async (dispatch) => {
  dispatch(setLoading());

  try {
    const notifications = await NotificationsAPI.getNotifications();

    dispatch(fetchNotificationsSuccess(notifications.data));
  } catch (error) {
    dispatch(setFailure('Something went wrong'));
  }
};

export const deleteNotification = (
  notificationId: CodeNotificationTypes['id']
): AppThunk => async (dispatch) => {
  dispatch(setLoading());

  try {
    await NotificationsAPI.deleteNotification(notificationId);

    dispatch(deleteNotificationSuccess(notificationId));
  } catch (error) {
    dispatch(setFailure('Something went wrong'));
  }
};

export const setNotificationsInactive = (
  notificationsId: CodeNotificationTypes['id'][]
): AppThunk => async (dispatch) => {
  dispatch(setLoading());

  try {
    const data = { isActive: false };
    const notifications = await Promise.all(
      notificationsId.map((notificationId) =>
        NotificationsAPI.updateNotification(notificationId, data)
      )
    );

    dispatch(updateNotificationsSuccess(notifications));
  } catch (error) {
    dispatch(setFailure('Something went wrong'));
  }
};
