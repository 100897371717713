export enum FieldOfLaw {
  ADMINISTRATIVE = 1,
  ANIMAL,
  ANTITRUST,
  ART,
  BANKING,
  BANKRUPTCY,
  BUSINESS,
  CANON,
  CIVIL,
  COMMON,
  COMMUNICATIONS,
  COMPANY,
  COMPUTER,
  CONSTITUTIONAL,
  CONSTRUCTION,
  CONSUMER,
  CONTRACTS,
  COPYRIGHT,
  CRIMINAL,
  CULTURAL,
  CUSTOMS,
  DIVORCE,
  DRUG,
  EDUCATION,
  EMPLOYMENT,
  ENERGY,
  ENTERTAINMENT,
  ENVIRONMENTAL,
  FAMILY,
  GAMING,
  HEALTH,
  HEBRAIC,
  INHERITANCE,
  ISLAMIC,
  INSURANCE,
  IP,
  INTERNATIONAL_PRIVATE,
  INTERNATIONAL_PUBLIC,
  INTERNET,
  LAND,
  LITIGATION,
  MEDIA,
  MEDICAL,
  M_AND_A,
  MILITARY,
  NATIONALITY,
  PRIVATE,
  PROCEDURAL,
  PRODUCT,
  PROPERTY,
  PUBLIC,
  ROMAN,
  SCIENCE,
  SECURITIES,
  SOCIAL,
  SPACE,
  SPORTS,
  TAX,
  TECHNOLOGY,
  TRANSPORT,
  TRUST,
}

export const fieldOfLawName: { [key: string]: string } = Object.entries(FieldOfLaw).reduce(
  (accumulator, [fieldOfLawName, fieldOfLaw]) => ({
    ...accumulator,
    [fieldOfLaw]: fieldOfLawName,
  }),
  {}
);
