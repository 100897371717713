import styled from 'styled-components/macro';

import IconButton from 'components/IconButton';

export const Header = styled.div`
  display: grid;
  border-bottom: var(--border-common);
  grid-template-columns: 1fr auto;
`;

export const CloseButton = styled(IconButton)`
  min-height: 70px;
  height: 100%;
  border-left: var(--border-common);
`;
