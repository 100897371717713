import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';

import { RootState } from 'rootReducer';
import { processBookmarks } from 'utils/selectionsProcessors';
import { CodeChangeAction, IDocument } from '@codex/shared';
import { RightPanelType } from './codeEditSlice';

export const getActiveDocumentId = (state: RootState) => state.codeEdit.activeDocumentId;
export const getNewDocumentId = (state: RootState) => state.codeEdit.newDocumentId;

export const getDocuments = (state: RootState) =>
  state.codeEdit.code.data && state.codeEdit.code.data.documents;

export const getAddedDocuments = (state: RootState) => (state.codeEdit.codeChanges.data.documents
  ?.filter(change => change.action === CodeChangeAction.CREATE) || [])
  .map(change => change.payload as IDocument);

export const getUpdatedDocuments = (state: RootState) =>
  state.codeEdit.codeWithUpdates.data && state.codeEdit.codeWithUpdates.data.documents;

export const getActiveSelectionId = (state: RootState) => state.codeEdit.activeSelectionId;

export const getPageNumber = (state: RootState) => state.codeEdit.pageNumber;

export const getCode = (state: RootState) => state.codeEdit.code.data;

export const getCodeWithUpdates = (state: RootState) => state.codeEdit.codeWithUpdates.data;

export const getCodeError = (state: RootState) => state.codeEdit.code.error;

export const getSelections = (state: RootState) => state.codeEdit.selections.data;

export const getChanges = (state: RootState) => state.codeEdit.changes.data;

export const getCodeChanges = (state: RootState) => state.codeEdit.codeChanges.data;

export const getSelectionsByPage = createSelector(getSelections, (selections) =>
  memoize((pageIndex: number) =>
    selections.filter((selection) => selection.startPage === pageIndex)
  )
);

export const getChangesByPage = createSelector(getChanges, (changes) =>
  memoize((pageIndex: number) =>
    changes.filter((change) => change.startPage === pageIndex)
  )
);

export const getSelectionsIsLoading = (state: RootState) => state.codeEdit.selections.isLoading;

export const getPreventDeactivation = (state: RootState) => state.codeEdit.preventDeactivation;

export const getRightPanel = (state: RootState) => state.codeEdit.rightPanel;

export const getDocument = createSelector(
  [getDocuments, getAddedDocuments, getActiveDocumentId, getUpdatedDocuments, getNewDocumentId, getRightPanel],
  (documents, addedDocuments, activeDocumentId, updatedDocuments, newDocumentId, rightPanel) => {
    if (
      rightPanel === RightPanelType.CODE_UPDATE &&
      typeof newDocumentId === 'number' && updatedDocuments?.length &&
      !!updatedDocuments.find((document) => document.id === newDocumentId)
    ) {
      const document: IDocument = updatedDocuments.find((document) => document.id === newDocumentId)!;
      return ({
        ...document,
        id: activeDocumentId!,
      });
    }

    const allDocuments = [...(documents || []), ...addedDocuments];

    return allDocuments.find((document) => document.id === activeDocumentId) || null;

  }
);

export const getSelection = createSelector(
  [getSelections, getActiveSelectionId],
  (selections, activeSelectionId) =>
    selections?.find((selection) => selection.id === activeSelectionId)
);

export const getScale = (state: RootState) => state.codeEdit.scale;

export const getLeftPanel = (state: RootState) => state.codeEdit.leftPanel;

export const getToolbarScale = (state: RootState) => state.codeEdit.toolbar.scale;

export const getToolbarPageNumber = (state: RootState) => state.codeEdit.toolbar.pageNumber;

export const getGeneralNotes = (state: RootState) => state.codeEdit.generalNotes.data;

export const getGeneralNotesIsLoading = (state: RootState) => state.codeEdit.generalNotes.isLoading;

export const getBookmarks = (state: RootState) => processBookmarks(getSelections(state));

export const getBookmarksByPage = (pageIndex: number) =>
  createSelector(getBookmarks, (bookmarks) =>
    bookmarks.filter((bookmark) => bookmark.startPage === pageIndex)
  );

export const getBookmarksIsLoading = (state: RootState) => state.codeEdit.bookmarks.isLoading;

export const getUsers = (state: RootState) => state.codeEdit.users.data;

export const annotationToShare = (state: RootState) => state.codeEdit.annotationToShare;

export const annotationSharedSuccessfully = (state: RootState) => state.codeEdit.annotationSharedSuccessfully;

export const generalNoteToShare = (state: RootState) => state.codeEdit.generalNoteToShare;

export const generalNoteSharedSuccessfully = (state: RootState) => state.codeEdit.generalNoteSharedSuccessfully;

export const getReviewError = (state: RootState) => state.codeEdit.review.error;
