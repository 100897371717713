import reduxWebsocket from '@giantmachines/redux-websocket';

import { WsPrefix } from 'api/ws';
import { WebsocketCodeActions, WebsocketUserActions } from './types';

const reduxWebsocketMiddlewareUser = reduxWebsocket({ prefix: WsPrefix.USER });
const reduxWebsocketMiddlewareCode = reduxWebsocket({ prefix: WsPrefix.CODE });

const middleware = [reduxWebsocketMiddlewareUser, reduxWebsocketMiddlewareCode];

export const middlewareIngoredActions = [
  ...Object.values(WebsocketCodeActions),
  ...Object.values(WebsocketUserActions),
];

export default middleware;
