export enum FileType {
  DOCUMENT = 'documents',
  ATTACHMENT = 'attachments',
}

export enum FileContentType {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  MP3 = 'audio/mp3',
  MP4 = 'video/mp4',
  M4A = 'audio/x-m4a',
  MPEG = 'audio/mpeg',
  ZIP = 'application/zip',
  WEBMV = 'video/webm',
  WEBMA = 'audio/webm',
}

export interface IFileSignRequest {
  contentType: FileContentType;
}
