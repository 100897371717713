import React from 'react';
import styled, { css } from 'styled-components/macro';

export enum Size {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

const SmallSize = css`
  width: 40px;
  height: 40px;
  font-size: 16px;
`;

const MediumSize = css`
  width: 56px;
  height: 56px;
  font-size: 24px;
`;

const BigSize = css`
  width: 70px;
  height: 70px;
  font-size: 26px;
`;

type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean;
  size?: Size;
};

const BaseIconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ isActive, size, ...rest }, ref) => (
    <button type="button" ref={ref} {...(rest as React.ButtonHTMLAttributes<HTMLButtonElement>)} />
  )
);

export const IconButton = styled(BaseIconButton)<IconButtonProps>`
  padding: 0;
  color: var(--color-gray-1100);
  background: var(--color-white);
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--color-gray-300);
  }

  &:active {
    background-color: var(--color-gray-400);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.2;

    &:hover,
    &:active {
      background-color: transparent;
    }
  }

  ${({ size }) => {
    switch (size) {
      case Size.SMALL:
        return SmallSize;
      case Size.MEDIUM:
        return MediumSize;
      case Size.BIG:
        return BigSize;
    }
  }};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--color-gray-400);

      &:hover {
        background-color: var(--color-gray-400);
      }
    `};
`;

IconButton.defaultProps = {
  size: Size.MEDIUM,
};

export default IconButton;
