import React, { useState, createContext } from 'react';

import { TranslationsContextType } from './types';
import { Locale, getMessages, getUserLocale, isSupportedLocale } from './translationsConfig';

export const TranslationsContext = createContext<TranslationsContextType>({
  locale: Locale.English,
  messages: getMessages(),
  selectLanguage: () => {},
});

export const TranslationsContextProvider: React.FC = (props) => {
  const initialLocale = getUserLocale();

  const [locale, setLocale] = useState(initialLocale);
  const [messages, setMessages] = useState(getMessages(initialLocale));

  const selectLanguage = (locale: string) => {
    isSupportedLocale(locale) && localStorage.setItem('locale', locale);
    setLocale(locale);
    setMessages(getMessages(locale));
  };

  return (
    <TranslationsContext.Provider value={{ locale, messages, selectLanguage }}>
      {props.children}
    </TranslationsContext.Provider>
  );
};
