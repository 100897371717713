import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICodeUser, ICode, CodeInvitationAction } from '@codex/shared/interfaces';
import filter from 'lodash/filter';

import * as ShareAPI from 'api/share';
import { AppThunk } from 'store';
import { logout } from 'features/auth/authSlice';

interface CodeShareState {
  data: ICodeUser[];
  isLoading: boolean;
  error: string | null;
}

const initialState: CodeShareState = {
  data: [],
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: 'codeShare',
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    invitationSucess(state) {
      state.isLoading = false;
    },
    fetchSharedUsersSuccess(state, { payload }: PayloadAction<ICodeUser[]>) {
      state.data = payload;
      state.isLoading = false;
    },
    addNewSharedUsersSuccess(state, { payload }: PayloadAction<ICodeUser[]>) {
      const updatedSharedCodeUsers = [...state.data, ...payload];

      state.data = updatedSharedCodeUsers;
      state.isLoading = false;
    },
    removeSharedUserSuccess(state, { payload }: PayloadAction<ICodeUser['id']>) {
      const updatedSharedCodeUsers = filter(state.data, (user: ICodeUser) => user.id !== payload);

      state.data = updatedSharedCodeUsers;
      state.isLoading = false;
    },
    updateSharedUserAccessSuccess(
      state,
      { payload }: PayloadAction<Pick<ICodeUser, 'access' | 'id'>>
    ) {
      const updatedSharedCodeUsers = state.data.map((user: ICodeUser) =>
        user.id === payload.id ? { ...user, access: payload.access } : user
      );

      state.data = updatedSharedCodeUsers;
      state.isLoading = false;
    },
  },
  extraReducers: {
    [logout.toString()]: () => {
      return { ...initialState };
    },
  },
});

export const {
  setLoading,
  setFailure,
  fetchSharedUsersSuccess,
  addNewSharedUsersSuccess,
  removeSharedUserSuccess,
  updateSharedUserAccessSuccess,
  invitationSucess,
} = slice.actions;

export default slice.reducer;

export const fetchSharedCodeUsers = (codeId: number): AppThunk => async (dispatch) => {
  dispatch(setLoading());

  try {
    const sharedCodeUsers = await ShareAPI.getSharedCodeUsers(codeId);

    dispatch(fetchSharedUsersSuccess(sharedCodeUsers.data));
  } catch (error) {
    dispatch(setFailure('Code does not exist'));
  }
};

export const addNewSharedCodeUsers = (
  codeId: number,
  data: ShareAPI.shareCodeData
): AppThunk => async (dispatch) => {
  dispatch(setLoading());

  try {
    const newSharedCodeUsers = await ShareAPI.shareCode(codeId, data);

    dispatch(addNewSharedUsersSuccess(newSharedCodeUsers.data));
  } catch (error) {
    dispatch(setFailure('Something went wrong, please try again'));
  }
};

export const deleteSharedCodeUser = (codeId: number, userId: ICodeUser['id']): AppThunk => async (
  dispatch
) => {
  dispatch(setLoading());

  try {
    await ShareAPI.removeSharedUser(codeId, userId);
    dispatch(removeSharedUserSuccess(userId));
  } catch (error) {
    dispatch(setFailure('Something went wrong, please try again'));
  }
};

export const updateSharedCodeUserAccess = ({
  codeId,
  userId,
  access,
}: ShareAPI.updateUserAccessConfig): AppThunk => async (dispatch) => {
  dispatch(setLoading());
  try {
    const updatedUser = await ShareAPI.updateSharedUserAccess({
      codeId,
      userId,
      access,
    });

    dispatch(updateSharedUserAccessSuccess(updatedUser));
  } catch (error) {
    dispatch(setFailure('Something went wrong, please try again'));
  }
};

export const postInvitationAction = (
  codeId: ICode['id'],
  action: CodeInvitationAction
): AppThunk => async (dispatch) => {
  dispatch(setLoading());

  const data = { action };

  try {
    await ShareAPI.postInvitationAction(codeId, data);

    dispatch(invitationSucess());
  } catch (error) {
    dispatch(setFailure('Something went wrong, please try again'));
  }
};

export const declineInvitationByToken = (token: string): AppThunk => async (dispatch) => {
  dispatch(setLoading());

  try {
    await ShareAPI.declineInvitationByToken(token);

    dispatch(invitationSucess());
  } catch (error) {
    dispatch(setFailure('Something went wrong, please try again'));
  }
};
