import { DefaultTheme } from 'styled-components';
import { SelectionColor } from '@codex/shared';
import mediaQueries from 'utils/mediaQueries';

const theme: DefaultTheme = {
  selection: {
    colors: {
      [SelectionColor.COLOR_1]: {
        normal: '#ffdce8',
        active: '#f0cfda',
      },
      [SelectionColor.COLOR_2]: {
        normal: '#e2d3ff',
        active: '#d5c7f0',
      },
      [SelectionColor.COLOR_3]: {
        normal: '#d5f7fa',
        active: '#c7e7e8',
      },
      [SelectionColor.COLOR_4]: {
        normal: '#cdf1d7',
        active: '#c0e1c9',
      },
      [SelectionColor.COLOR_5]: {
        normal: '#fff2c7',
        active: '#f0e3bc',
      },
      [SelectionColor.COLOR_6]: {
        normal: '#ffd5cb',
        active: '#f0c9c0',
      },
      [SelectionColor.COLOR_7]: {
        normal: '#E5BDBD',
        active: '#DBA1A1',
      },
      [SelectionColor.COLOR_8]: {
        normal: '#FCC2AB',
        active: '#FBA988',
      },
      [SelectionColor.COLOR_9]: {
        active: '#6B5AF1',
        normal: '#A69CF6',
      },
      [SelectionColor.COLOR_10]: {
        active: '#69E1D7',
        normal: '#A5EDE7',
      },
      [SelectionColor.COLOR_11]: {
        active: '#FDDD75',
        normal: '#FDEAAC',
      },
      [SelectionColor.COLOR_12]: {
        active: '#76D4FD',
        normal: '#ACE5FD',
      },
      [SelectionColor.COLOR_13]: {
        active: '#87C0D3',
        normal: '#B7D9E4',
      },
      [SelectionColor.COLOR_14]: {
        active: '#ECB7F2',
        normal: '#F3D3F7',
      },
      [SelectionColor.COLOR_15]: {
        active: '#FFA5B3',
        normal: '#FFC9D1',
      },
      [SelectionColor.COLOR_16]: {
        active: '#F2B79B',
        normal: '#F7D3C3',
      },
      [SelectionColor.COLOR_17]: {
        active: '#C09976',
        normal: '#D9C1AC',
      },
      [SelectionColor.COLOR_18]: {
        active: '#78C0B9',
        normal: '#AED9D5',
      },
      [SelectionColor.COLOR_19]: {
        active: '#ABBBC5',
        normal: '#CCD6DC',
      },
      [SelectionColor.COLOR_20]: {
        active: '#80928D',
        normal: '#B2BDBA',
      },
    },
  },
  media: mediaQueries,
};

export default theme;
