import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectionCoordinates } from '@codex/shared';

import * as DocumentsAPI from 'api/documents';
import { AppThunk } from 'store';

interface SearchResult extends ISelectionCoordinates {
  id: number;
}

interface SearchResults extends DocumentsAPI.SearchResponse {
  data: SearchResult[];
}

interface CodeEditSearchState {
  isLoading: boolean;
  data: SearchResults | null;
  error: string | null;
  isOpen: boolean;
  text: string;
  activeResult: SearchResult | null;
}

const initialState: CodeEditSearchState = {
  isLoading: false,
  data: null,
  error: null,
  isOpen: false,
  text: '',
  activeResult: null,
};

const slice = createSlice({
  name: 'codeEditSearch',
  initialState,
  reducers: {
    setSearchStart(state) {
      state.isLoading = true;
      state.activeResult = null;
    },
    setSearchSuccess(state, { payload }: PayloadAction<DocumentsAPI.SearchResponse>) {
      state.isLoading = false;
      state.data = {
        ...payload,
        data: payload.data.map((result, index) => ({ ...result, id: index + 1 })),
      };
    },
    setSearchFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    resetSearch() {
      return { ...initialState };
    },
    setActiveResult(state, { payload }: PayloadAction<SearchResult | null>) {
      state.activeResult = payload;
    },
    setIsOpen(state, { payload }: PayloadAction<boolean>) {
      state.isOpen = payload;
    },
    resetText(state) {
      state.text = '';
      state.data = null;
      state.activeResult = null;
    },
    setText(state, { payload }: PayloadAction<string>) {
      state.text = payload;
    },
  },
});

export const { setActiveResult, setIsOpen, resetSearch, resetText, setText } = slice.actions;

export default slice.reducer;

export const search = (documentId: number, text: string): AppThunk => async (dispatch) => {
  const { setSearchStart, setSearchSuccess, setSearchFailure, } = slice.actions;

  dispatch(setText(text));

  try {
    dispatch(setSearchStart());
    const selections = await DocumentsAPI.search(documentId, text);
    dispatch(setSearchSuccess(selections));
  } catch (error) {
    dispatch(setSearchFailure(error));
  }
};
