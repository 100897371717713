import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as WS from 'api/ws';
import * as wsSelectors from 'features/wsConnection/selectors';
import { connect } from 'features/wsConnection/wsConnectionSlice';
import { useUser } from 'features/auth/hooks';

export const useSetConnection = () => {
  const dispatch = useDispatch();
  const isUserWsConnected = useSelector(wsSelectors.getIsUserConnected);
  const [user] = useUser();

  useEffect(() => {
    if (!isUserWsConnected && !!user) {
      dispatch(connect());
      dispatch(WS.connectUser());
    }
    if (!!isUserWsConnected && !user) dispatch(WS.disconnectUser());

    return () => {
      if (isUserWsConnected) {
        dispatch(WS.disconnectUser());
      }
    };
  }, [isUserWsConnected, user, dispatch]);
};
