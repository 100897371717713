import { CodeNotificationTypes } from '@codex/shared/interfaces';

import api from './index';

export type INotificationsResponse = {
  data: CodeNotificationTypes[];
};

export async function getNotifications(): Promise<INotificationsResponse> {
  const url = `/api/v1/notifications`;
  const response = await api.get<INotificationsResponse>(url);

  return response.data;
}

export async function deleteNotification(
  notificationId: CodeNotificationTypes['id']
): Promise<void> {
  const url = `/api/v1/notifications/${notificationId}`;

  await api.delete(url);
}

export async function updateNotification(
  notificationId: CodeNotificationTypes['id'],
  data: Partial<CodeNotificationTypes>
): Promise<CodeNotificationTypes> {
  const url = `/api/v1/notifications/${notificationId}`;
  const response = await api.patch<CodeNotificationTypes>(url, data);

  return response.data;
}
