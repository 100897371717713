import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelection, INVALID_SELECTION_STATUSES } from '@codex/shared';

import * as SelectionsAPI from 'api/selections';
import { AppThunk } from 'store';

interface UnportedAnnotationsState {
  isLoading: boolean;
  data: ISelection[] | null;
  error: string | null;
}

const initialState: UnportedAnnotationsState = {
  isLoading: false,
  data: null,
  error: null,
};

const slice = createSlice({
  name: 'codeUnportedAnnotations',
  initialState,
  reducers: {
    setSelectionsStart(state) {
      state.isLoading = true;
    },
    setSelectionsSuccess(state, { payload }: PayloadAction<ISelection[]>) {
      state.isLoading = false;
      state.data = payload;
    },
    setSelectionsFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    resetUnportedAnnotations() {
      return { ...initialState };
    },
  },
});

export const {
  setSelectionsStart,
  setSelectionsSuccess,
  setSelectionsFailure,
  resetUnportedAnnotations,
} = slice.actions;

export default slice.reducer;

export const getSelections = (documentId: number): AppThunk => async (dispatch) => {
  const { setSelectionsStart, setSelectionsSuccess, setSelectionsFailure } = slice.actions;

  try {
    dispatch(setSelectionsStart());
    const code = await SelectionsAPI.getSelections(documentId, {
      status: INVALID_SELECTION_STATUSES,
    });
    dispatch(setSelectionsSuccess(code));
  } catch (error) {
    dispatch(setSelectionsFailure('Something went wrong. Please try again later.'));
  }
};
