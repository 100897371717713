import React from 'react';

import { Size } from 'components/IconButton';
import { ClearIcon } from 'components/Icons';
import { Header, CloseButton } from './ModalHeader.styled';

interface ModalHeaderProps {
  children?: React.ReactNode;
  onCloseClick?: () => void;
}

const ModalHeader = ({ children, onCloseClick }: ModalHeaderProps) => (
  <Header>
    {children}
    <CloseButton size={Size.BIG} onClick={onCloseClick} data-ga="close-modal">
      <ClearIcon />
    </CloseButton>
  </Header>
);

export default ModalHeader;
