import {
  ISelection,
  ISelectionComment,
  IListResponse,
  ISelectionAttachment,
  IChange
} from '@codex/shared';

import api from './index';
import { ShareAnnotationPayload } from '../features/codeEdit/types';

export type SelectionsResponse = IListResponse<ISelection>;
export type ChangesResponse = IListResponse<IChange>;
export type CreateSelectionData = Pick<
  ISelection,
  | 'documentId'
  | 'text'
  | 'color'
  | 'startPage'
  | 'startElement'
  | 'startCharacter'
  | 'endPage'
  | 'endElement'
  | 'endCharacter'
  | 'isBookmark'
  | 'isAnnotation'
  | 'name'
>;
export type UpdateSelectionData = Partial<Pick<ISelection, 'color'>>;
export type CreateCommentData = Pick<ISelectionComment, 'selectionId' | 'text'>;
export type UpdateCommentData = Partial<Pick<ISelectionComment, 'text'>>;
export type CreateAttachmentData = Pick<ISelectionAttachment, 'selectionId' | 'title' | 'uuid'>;
export type UpdateAttachmentData = Pick<ISelectionAttachment, 'title'>;

export async function getSelections(documentId: number, params?: object): Promise<ISelection[]> {
  const url = `/api/v1/documents/${documentId}/selections`;
  const {
    data: { data },
  } = await api.get<SelectionsResponse>(url, { params });

  return data;
}

export async function getChanges(documentId: number, params?: object): Promise<IChange[]> {
  const url = `/api/v1/documents/${documentId}/changes`;
  const {
    data: { data },
  } = await api.get<ChangesResponse>(url, { params });

  return data;
}

export async function createSelection(data: CreateSelectionData): Promise<ISelection> {
  const url = '/api/v1/selections';
  const response = await api.post<ISelection>(url, data);

  return response.data;
}

export async function updateSelection(
  selectionId: number,
  data: UpdateSelectionData
): Promise<ISelection> {
  const url = `/api/v1/selections/${selectionId}`;
  const response = await api.patch<ISelection>(url, data);

  return response.data;
}

export async function deleteSelection(selectionId: number): Promise<void> {
  const url = `/api/v1/selections/${selectionId}`;
  await api.delete(url);
}

export async function createComment(data: CreateCommentData): Promise<ISelectionComment> {
  const url = '/api/v1/selections/comments';
  const response = await api.post<ISelectionComment>(url, data);

  return response.data;
}

export async function deleteComment(commentId: number): Promise<void> {
  const url = `/api/v1/selections/comments/${commentId}`;
  await api.delete(url);
}

export async function updateComment(
  commentId: number,
  data: UpdateCommentData
): Promise<ISelectionComment> {
  const url = `/api/v1/selections/comments/${commentId}`;
  const response = await api.patch<ISelectionComment>(url, data);

  return response.data;
}

export async function createAttachment(data: CreateAttachmentData): Promise<ISelectionAttachment> {
  const url = '/api/v1/selections/attachments';
  const response = await api.post<ISelectionAttachment>(url, data);

  return response.data;
}

export async function deleteAttachment(attachmentId: number): Promise<void> {
  const url = `/api/v1/selections/attachments/${attachmentId}`;
  await api.delete(url);
}

export async function updateAttachment(
  attachmentId: number,
  data: UpdateAttachmentData
) {
  const url = `/api/v1/selections/attachments/${attachmentId}`;
  await api.patch(url, data);
}

export async function shareAnnotation(payload: ShareAnnotationPayload): Promise<void> {
  const url = `/api/v1/selections/share-annotation`;
  await api.post(url, payload);
}
