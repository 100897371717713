import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICode, ISelection, IDocument, IGeneralNote, IBookmark } from '@codex/shared/interfaces';

import * as CodesAPI from 'api/codes';
import * as SelectionsAPI from 'api/selections';
import * as DocumentsAPI from 'api/documents';
import * as GeneralNotesAPI from 'api/generalNotes';
import * as BookmarksAPI from 'api/bookmarks';
import { AppThunk } from 'store';
import debounceAction from 'utils/debounceAction';
import { setActiveResult } from '../codeEditSearch/codeEditSearchSlice';
import { logout } from '../auth/authSlice';

export enum LeftPanelType {
  ALL_VERSIONS = 'all-versions',
  BOOKMARKS = 'bookmarks',
  PAGES = 'pages',
}

export enum RightPanelType {
  GENERAL_NOTES = 'general-notes',
  ANNOTATIONS = 'annotations',
}

const INITIAL_SCALE = 2;
const INITIAL_PAGE_NUMBER = 1;
const INITIAL_LEFT_PANEL = LeftPanelType.ALL_VERSIONS;
const INITIAL_RIGHT_PANEL = RightPanelType.ANNOTATIONS;

export type ISelections = ISelection[];

interface CodeEditState {
  code: {
    isLoading: boolean;
    data: ICode | null;
    error: string | null;
  };
  selections: {
    isLoading: boolean;
    data: ISelections;
    error: string | null;
  };
  generalNotes: {
    isLoading: boolean;
    data: IGeneralNote[];
    error: string | null;
  };
  bookmarks: {
    isLoading: boolean;
    data: IBookmark[];
    error: string | null;
  };
  versions: {
    isLoading: boolean;
    data: IDocument[];
    error: string | null;
  };
  toolbar: {
    scale: number;
    pageNumber: number;
  };
  leftPanel: LeftPanelType | null;
  rightPanel: RightPanelType | null;
  scale: number;
  pageNumber: number;
  activeSelectionId: number | null;
  activeDocumentId: number | null;
}

const initialState: CodeEditState = {
  code: {
    isLoading: false,
    data: null,
    error: null,
  },
  selections: {
    isLoading: false,
    data: [],
    error: null,
  },
  generalNotes: {
    isLoading: false,
    data: [],
    error: null,
  },
  bookmarks: {
    isLoading: false,
    data: [],
    error: null,
  },
  versions: {
    isLoading: false,
    data: [],
    error: null,
  },
  toolbar: {
    scale: INITIAL_SCALE,
    pageNumber: INITIAL_PAGE_NUMBER,
  },
  leftPanel: INITIAL_LEFT_PANEL,
  rightPanel: INITIAL_RIGHT_PANEL,
  scale: INITIAL_SCALE,
  pageNumber: INITIAL_PAGE_NUMBER,
  activeSelectionId: null,
  activeDocumentId: null,
};

const slice = createSlice({
  name: 'codeDocumentVersions',
  initialState,
  reducers: {
    setCodeStart({ code }) {
      code.isLoading = true;
      code.data = null;
    },
    setCodeSuccess({ code }, { payload }: PayloadAction<ICode>) {
      code.isLoading = false;
      code.data = payload;
    },
    setCodeFailure({ code }, { payload }: PayloadAction<string>) {
      code.isLoading = false;
      code.error = payload;
    },
    resetDocumentVersionsCode() {
      return { ...initialState };
    },
    setSelectionsStart({ selections }) {
      selections.isLoading = true;
      selections.data = [];
    },
    setSelectionsSuccess({ selections }, { payload }: PayloadAction<ISelection[]>) {
      selections.isLoading = false;
      selections.data = payload;
    },
    setSelectionsFailure({ selections }, { payload }: PayloadAction<string>) {
      selections.isLoading = false;
      selections.error = payload;
    },
    setVersionsStart({ versions }) {
      versions.isLoading = true;
      versions.data = [];
    },
    setVersionsSuccess({ versions }, { payload }: PayloadAction<IDocument[]>) {
      versions.isLoading = false;
      versions.data = payload;
    },
    setVersionsFailure({ versions }, { payload }: PayloadAction<string>) {
      versions.isLoading = false;
      versions.error = payload;
    },
    setGeneralNotesStart({ generalNotes }) {
      generalNotes.isLoading = true;
      generalNotes.data = [];
    },
    setGeneralNotesSuccess({ generalNotes }, { payload }: PayloadAction<IGeneralNote[]>) {
      generalNotes.isLoading = false;
      generalNotes.data = payload;
    },
    setGeneralNotesFailure({ generalNotes }, { payload }: PayloadAction<string>) {
      generalNotes.isLoading = false;
      generalNotes.error = payload;
    },
    setBookmarksStart({ bookmarks }) {
      bookmarks.isLoading = true;
      bookmarks.data = [];
    },
    setBookmarksSuccess({ bookmarks }, { payload }: PayloadAction<IBookmark[]>) {
      bookmarks.isLoading = false;
      bookmarks.data = payload;
    },
    setBookmarksFailure({ bookmarks }, { payload }: PayloadAction<string>) {
      bookmarks.isLoading = false;
      bookmarks.error = payload;
    },
    setScale(state, { payload }: PayloadAction<number>) {
      state.scale = payload;
    },
    setPageNumber(state, { payload }: PayloadAction<number>) {
      state.pageNumber = payload;
    },
    setLeftPanel(state, { payload }: PayloadAction<LeftPanelType | null>) {
      state.leftPanel = payload;
    },
    setRightPanel(state, { payload }: PayloadAction<RightPanelType | null>) {
      state.rightPanel = payload;
    },
    setActiveDocumentId(state, { payload }: PayloadAction<number>) {
      state.activeDocumentId = payload;
      state.scale = initialState.scale;
      state.pageNumber = initialState.pageNumber;

      state.toolbar.scale = initialState.toolbar.scale;
      state.toolbar.pageNumber = initialState.toolbar.pageNumber;
    },
    setToolbarScale({ toolbar }, { payload }: PayloadAction<number>) {
      toolbar.scale = payload;
    },
    setToolbarPageNumber({ toolbar }, { payload }: PayloadAction<number>) {
      toolbar.pageNumber = payload;
    },
    setActiveSelectionId(state, { payload }: PayloadAction<number | null>) {
      state.activeSelectionId = payload;
    },
  },
  extraReducers: {
    [setActiveResult.toString()]: (state, { payload }) => {
      const pageNumber = payload.startPage + 1;

      if (pageNumber === state.pageNumber) {
        return;
      }

      state.pageNumber = pageNumber;
      state.toolbar.pageNumber = pageNumber;
    },
    [logout.toString()]: () => {
      return { ...initialState };
    },
  },
});

export const {
  resetDocumentVersionsCode,
  setLeftPanel,
  setRightPanel,
  setActiveDocumentId,
  setScale,
  setToolbarScale,
  setToolbarPageNumber,
  setActiveSelectionId,
} = slice.actions;

export default slice.reducer;

export const getCode = (codeId: number): AppThunk => async (dispatch) => {
  const { setCodeStart, setCodeSuccess, setCodeFailure } = slice.actions;

  try {
    dispatch(setCodeStart());
    const code = await CodesAPI.getCode(codeId);
    dispatch(setCodeSuccess(code));
  } catch (error) {
    dispatch(setCodeFailure('Code does not exist'));
  }
};

export const getSelections = (documentId: number, params?: Object): AppThunk => async (
  dispatch
) => {
  const { setSelectionsStart, setSelectionsSuccess, setSelectionsFailure } = slice.actions;

  try {
    dispatch(setSelectionsStart());
    const selections = await SelectionsAPI.getSelections(documentId, params);
    dispatch(setSelectionsSuccess(selections));
  } catch (error) {
    dispatch(setSelectionsFailure(error));
  }
};

export const getDocumentVersions = (documentId: number): AppThunk => async (dispatch) => {
  const { setVersionsStart, setVersionsSuccess, setVersionsFailure } = slice.actions;

  try {
    dispatch(setVersionsStart());
    const versions = await DocumentsAPI.getDocumentVersions(documentId);
    dispatch(setVersionsSuccess(versions.data));
  } catch (error) {
    dispatch(setVersionsFailure('Document does not exist'));
  }
};

export const debouncedSetScale = debounceAction(setScale, 200);
export const debouncedSetPageNumber = debounceAction(slice.actions.setPageNumber, 200);

export const updateScale = (scale: number): AppThunk => (dispatch) => {
  dispatch(setToolbarScale(scale));
  dispatch(debouncedSetScale(scale));
};

export const updatePageNumber = (pageNumber: number): AppThunk => (dispatch) => {
  dispatch(setToolbarPageNumber(pageNumber));
  dispatch(debouncedSetPageNumber(pageNumber));
};

export const setPageNumber = (pageNumber: number): AppThunk => (dispatch) => {
  dispatch(setToolbarPageNumber(pageNumber));
  dispatch(slice.actions.setPageNumber(pageNumber));
};

export const getGeneralNotes = (documentId: number): AppThunk => async (dispatch) => {
  const { setGeneralNotesStart, setGeneralNotesSuccess, setGeneralNotesFailure } = slice.actions;

  try {
    dispatch(setGeneralNotesStart());
    const generalNotes = await GeneralNotesAPI.getGeneralNotes(documentId);
    dispatch(setGeneralNotesSuccess(generalNotes));
  } catch (error) {
    dispatch(setGeneralNotesFailure(error));
  }
};

/*
* @deprecated use selections with isBookmark flag
*/
export const getBookmarks = (documentId: number): AppThunk => async (dispatch) => {
  const { setBookmarksStart, setBookmarksSuccess, setBookmarksFailure } = slice.actions;

  try {
    dispatch(setBookmarksStart());
    const bookmarks = await BookmarksAPI.getBookmarks(documentId);
    dispatch(setBookmarksSuccess(bookmarks));
  } catch (error) {
    dispatch(setBookmarksFailure(error));
  }
};
