import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import loadable from '@loadable/component';
import Helmet from 'react-helmet';
import { toast } from 'react-toastify';
import { IntlProvider } from 'react-intl';

import { useFetchProfile, useLogoutOnStorageChange } from 'features/auth/hooks';
import { useSetConnection } from 'features/wsConnection/hooks';
import { getError } from 'features/auth/selectors';
import { WarningInfoIcon } from 'components/Icons';
import PrivateRoute from 'components/PrivateRoute';
import { TranslationsContext, TranslationsContextProvider, Locale } from 'components/Translations';
import MobileWarningModal from 'components/MobileWarningModal';

const Homepage = loadable(() => import('features/homepage/Homepage'));
const Codes = loadable(() => import('features/codes/Codes'));
const Library = loadable(() => import('features/library/Library'));
const Account = loadable(() => import('features/account/Account'));
const Files = loadable(() => import('features/files/Files'));

const App = () => {
  const errorMessage = useSelector(getError);

  useFetchProfile();
  useLogoutOnStorageChange();
  useSetConnection();

  const isPrivateRoute = useRouteMatch(['/library', '/codes', '/account', '/files']);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const showModal = Boolean(isPrivateRoute && isModalOpen);

  useEffect(() => {
    if (errorMessage) {
      toast(
        <>
          <WarningInfoIcon hasMarginRight size={19} />
          <div>{errorMessage}</div>
        </>
      );
    }
  }, [errorMessage]);

  return (
    <TranslationsContextProvider>
      <TranslationsContext.Consumer>
        {({ messages, locale }) => (
          <IntlProvider messages={messages} locale={locale} defaultLocale={Locale.English}>
            <Helmet defaultTitle="Codex102" />
            <Switch>
              <PrivateRoute path="/library">
                <Library />
              </PrivateRoute>
              <PrivateRoute path="/codes">
                <Codes />
              </PrivateRoute>
              <PrivateRoute path="/account">
                <Account />
              </PrivateRoute>
              <PrivateRoute path="/files">
                <Files />
              </PrivateRoute>
              <Route path="/">
                <Homepage />
              </Route>
            </Switch>
            {showModal && <MobileWarningModal onClose={() => setIsModalOpen(false)} />}
          </IntlProvider>
        )}
      </TranslationsContext.Consumer>
    </TranslationsContextProvider>
  );
};

export default App;
