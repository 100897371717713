import { IListResponse, IGeneralNote, IGeneralNoteAttachment } from '@codex/shared';
import { ShareGeneralNotePayload } from 'features/codeEdit/types';

import api from './index';

export type GeneralNotesResponse = IListResponse<IGeneralNote>;
export type CreateGeneralNoteData = Pick<IGeneralNote, 'documentId' | 'text'>;
export type UpdateGeneralNoteData = Pick<IGeneralNote, 'text'>;
export type CreateAttachmentData = Pick<IGeneralNoteAttachment, 'noteId' | 'title' | 'uuid'>;
export type UpdateAttachmentData = Pick<IGeneralNoteAttachment, 'title'>;

export async function getGeneralNotes(documentId: number): Promise<IGeneralNote[]> {
  const url = `/api/v1/documents/${documentId}/notes`;
  const {
    data: { data },
  } = await api.get<GeneralNotesResponse>(url);

  return data;
}

export async function createGeneralNote(data: CreateGeneralNoteData): Promise<IGeneralNote> {
  const url = '/api/v1/notes';
  const response = await api.post<IGeneralNote>(url, data);

  return response.data;
}

export async function updateGeneralNote(
  generalNoteId: number,
  data: UpdateGeneralNoteData
): Promise<IGeneralNote> {
  const url = `/api/v1/notes/${generalNoteId}`;
  const response = await api.patch<IGeneralNote>(url, data);

  return response.data;
}

export async function deleteGeneralNote(generalNoteId: number): Promise<void> {
  const url = `/api/v1/notes/${generalNoteId}`;
  await api.delete(url);
}

export async function createAttachment(
  data: CreateAttachmentData
): Promise<IGeneralNoteAttachment> {
  const url = '/api/v1/notes/attachments';
  const response = await api.post<IGeneralNoteAttachment>(url, data);

  return response.data;
}

export async function deleteAttachment(attachmentId: number): Promise<void> {
  const url = `/api/v1/notes/attachments/${attachmentId}`;
  await api.delete(url);
}

export async function updateGeneralNoteAttachment(noteId: number, data: UpdateAttachmentData) {
  // TODO make request to backend see @COD-493 @COD-494
  // const url = `/api/v1/notes/attachments/${attachmentId}`;
  // await api.post(url, data);

  return new Promise((resolve) => {
    setTimeout(resolve, 500);
  });
}

export async function shareGeneralNote({ noteId, emails, message }: ShareGeneralNotePayload): Promise<void> {
  const url = `/api/v1/notes/${noteId}/share`;
  await api.post(url, { emails, message });
}
