export enum Color {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  TRANSPARENT = 'transparent',
}

export enum Size {
  SMALLEST = 'smallest',
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export const SIZE_TO_HEIGHT = {
  [Size.SMALLEST]: '40px',
  [Size.SMALL]: '48px',
  [Size.MEDIUM]: '56px',
  [Size.BIG]: '72px',
};

export const SIZE_TO_PADDING = {
  [Size.SMALLEST]: '0 18px',
  [Size.SMALL]: '0 18px',
  [Size.MEDIUM]: '0 24px',
  [Size.BIG]: '0 24px',
};
