import React from 'react';
import axios, { CancelTokenSource } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICode, FileContentType, IDocument } from '@codex/shared/interfaces';
import { toast } from 'react-toastify';
import orderBy from 'lodash/orderBy';

import { WarningInfoIcon } from 'components/Icons';
import * as CodesAPI from 'api/codes';
import * as FilesAPI from 'api/files';
import * as DocumentsAPI from 'api/documents';
import { AppThunk } from 'store';
import { ITemporaryDocument } from './types';
import { logout } from '../auth/authSlice';

let DOCUMENT_ID = -1;

const tokens: { [key: number]: CancelTokenSource } = {};

interface CodeCreateState {
  isLoading: boolean;
  data: ICode | null;
  error: string | null;
  temporaryDocuments: ITemporaryDocument[];
}

const initialState: CodeCreateState = {
  isLoading: false,
  data: null,
  error: null,
  temporaryDocuments: [],
};

const slice = createSlice({
  name: 'codeCreate',
  initialState,
  reducers: {
    getCodeCreateStart(state) {
      state.isLoading = true;
    },
    getCodeCreateSuccess(state, { payload }: PayloadAction<ICode>) {
      state.data = { ...payload, documents: [] };
      state.isLoading = false;
    },
    getCodeCreateFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    addDocument(state, { payload }: PayloadAction<IDocument>) {
      state.data?.documents.push(payload);
    },
    addTemporaryDocument(state, { payload }: PayloadAction<ITemporaryDocument>) {
      state.temporaryDocuments.push(payload);
    },
    resetCodeCreate() {
      return { ...initialState };
    },
    updateCode(state, { payload }: PayloadAction<Partial<ICode>>) {
      if (!state.data) {
        return;
      }

      Object.assign(state.data, payload);
    },
    updateDocument(state, { payload: { id, ...data } }: PayloadAction<Partial<IDocument>>) {
      const document = state.data?.documents.find((document) => document.id === id);

      if (!document) {
        return;
      }

      Object.assign(document, data);
    },
    updateTemporaryDocument(
      state,
      { payload: { id, ...data } }: PayloadAction<Partial<ITemporaryDocument>>
    ) {
      const document = state.temporaryDocuments.find((document) => document.id === id);

      if (!document) {
        return;
      }

      Object.assign(document, data);
    },
    updateDocumentOrder(
      state,
      { payload: { id, order } }: PayloadAction<{ id: number; order: number }>
    ) {
      const data = state.data;

      if (!data) {
        return;
      }

      const result = orderBy(data.documents, 'order', 'asc');
      const index = data.documents.findIndex((document) => document.id === id);
      const [removed] = result.splice(index, 1);
      result.splice(order, 0, removed);
      result.forEach((document, index) => {
        document.order = index;
      });
      data.documents = result;
    },
    deleteDocument(state, { payload: id }: PayloadAction<number>) {
      if (!state.data) {
        return;
      }

      state.data.documents.splice(
        state.data.documents.findIndex((document) => document.id === id),
        1
      );
    },
    deleteTemporaryDocument(state, { payload: id }: PayloadAction<number>) {
      state.temporaryDocuments.splice(
        state.temporaryDocuments.findIndex((document) => document.id === id),
        1
      );
    },
  },
  extraReducers: {
    [logout.toString()]: () => {
      return { ...initialState };
    },
  },
});

export const {
  getCodeCreateStart,
  getCodeCreateSuccess,
  getCodeCreateFailure,
  resetCodeCreate,
  updateCode,
} = slice.actions;

export default slice.reducer;

export const createCode = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getCodeCreateStart());
    const code = await CodesAPI.createCode();
    dispatch(getCodeCreateSuccess(code));
  } catch (error) {
    dispatch(getCodeCreateFailure(error));
  }
};

export const saveCode = (codeId: number, data: Partial<ICode>): AppThunk => async (dispatch) => {
  try {
    await CodesAPI.updateCode(codeId, data);
  } catch (error) {
    console.warn(error);
  }
};

export const addDocument = (codeId: number, document: File): AppThunk => async (dispatch) => {
  const {
    addDocument,
    addTemporaryDocument,
    updateTemporaryDocument,
    deleteTemporaryDocument,
  } = slice.actions;
  const { name: title, size } = document;
  const id = DOCUMENT_ID--;
  const source = axios.CancelToken.source();
  let savedDocument: IDocument;
  let uuid: string;

  dispatch(addTemporaryDocument({ id, title, size, loaded: 0 }));
  tokens[id] = source;

  try {
    uuid = await FilesAPI.upload(document, FileContentType.PDF, {
      cancelToken: source.token,
      onUploadProgress({ loaded }) {
        dispatch(updateTemporaryDocument({ id, loaded }));
      },
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      delete tokens[id];
      return;
    }

    console.error(error);
    return;
  }

  delete tokens[id];

  try {
    savedDocument = await DocumentsAPI.createDocument(codeId, title, uuid);
  } catch (error) {
    toast(
      <>
        <WarningInfoIcon hasMarginRight size={19} />
        <div>
          Document <strong>{title}</strong> is not valid PDF document
        </div>
      </>
    );
    dispatch(deleteTemporaryDocument(id));
    return;
  }

  dispatch(deleteTemporaryDocument(id));
  dispatch(addDocument(savedDocument));
};

export const deleteDocument = (documentId: number): AppThunk => async (dispatch) => {
  const { deleteDocument } = slice.actions;

  dispatch(deleteDocument(documentId));
  DocumentsAPI.deleteDocument(documentId);
};

export const deleteTemporaryDocument = (documentId: number): AppThunk => async (dispatch) => {
  const token = tokens[documentId];
  const { deleteTemporaryDocument } = slice.actions;

  dispatch(deleteTemporaryDocument(documentId));
  token.cancel();
};

export const cancelUploadsInProgress = (): AppThunk => async (dispatch) => {
  Object.values(tokens).forEach((token) => {
    token.cancel();
  });
};

export const updateDocument = (
  documentId: number,
  data: DocumentsAPI.UpdateDocumentData
): AppThunk => async (dispatch) => {
  const { updateDocument } = slice.actions;

  await DocumentsAPI.updateDocument(documentId, data);
  dispatch(updateDocument({ id: documentId, ...data }));
};

export const updateDocumentOrder = (documentId: number, order: number): AppThunk => async (
  dispatch
) => {
  const { updateDocumentOrder } = slice.actions;

  dispatch(updateDocumentOrder({ id: documentId, order }));
  await DocumentsAPI.updateDocument(documentId, { order });
};
