import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICode, IOrderBy } from '@codex/shared/interfaces';

import * as CodesAPI from 'api/codes';
import { AppThunk } from 'store';
import { SORT_OPTIONS } from '../../constants';
import { logout } from '../auth/authSlice';

type SearchParams = {
  title?: string;
  isPublic?: boolean;
} & IOrderBy;

interface LibraryState {
  data: ICode[] | null;
  isLoading: boolean;
  error: string | null;
  searchParams: SearchParams;
}

const initialState: LibraryState = {
  data: null,
  isLoading: false,
  error: null,
  searchParams: {
    orderBy: SORT_OPTIONS[0].value,
    isPublic: true,
  },
};

const slice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    setCodesStart(state) {
      state.isLoading = true;
    },
    setCodesSuccess(state, { payload }: PayloadAction<CodesAPI.CodesResponse>) {
      state.data = payload.data;
      state.isLoading = false;
    },
    setCodesFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    setSearchParams(state, { payload }: PayloadAction<Partial<SearchParams>>) {
      Object.assign(state.searchParams, payload);
    },
    resetSearchParams(state) {
      state.searchParams = { ...initialState.searchParams };
    },
  },
  extraReducers: {
    [logout.toString()]: () => {
      return { ...initialState };
    },
  },
});

export const { setSearchParams, resetSearchParams } = slice.actions;

export default slice.reducer;

export const getCodes = (params: SearchParams): AppThunk => async (dispatch) => {
  const { setCodesStart, setCodesSuccess, setCodesFailure } = slice.actions;

  try {
    dispatch(setCodesStart());
    const codes = await CodesAPI.getCodes(params);
    dispatch(setCodesSuccess(codes));
  } catch (error) {
    dispatch(setCodesFailure(error));
  }
};
