import { FileContentType, FieldOfLaw, Country, CodeAccess } from '@codex/shared';
import sortBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';

import { Icon, PdfIcon, Mp3Icon, DocIcon, ImageIcon, Mp4Icon } from 'components/Icons';

type FileContentTypeToIcon = {
  [key in FileContentType]: typeof Icon;
};

type FileContentTypeToName = {
  [key in FileContentType]: string;
};

type FieldOfLawToLabel = {
  [key in FieldOfLaw]: string;
};

type FieldOfLawToTag = FieldOfLawToLabel;

export const FILE_CONTENT_TYPE_TO_ICON: FileContentTypeToIcon = {
  [FileContentType.PDF]: PdfIcon,
  [FileContentType.DOC]: DocIcon,
  [FileContentType.DOCX]: DocIcon,
  [FileContentType.JPEG]: ImageIcon,
  [FileContentType.JPG]: ImageIcon,
  [FileContentType.PNG]: ImageIcon,
  [FileContentType.MP3]: Mp3Icon,
  [FileContentType.MP4]: Mp4Icon,
  [FileContentType.M4A]: Mp3Icon,
  [FileContentType.MPEG]: Mp3Icon,
  [FileContentType.WEBMA]: Mp3Icon,
  [FileContentType.WEBMV]: Mp4Icon,
  [FileContentType.ZIP]: DocIcon,
};

export const FILE_CONTENT_TYPE_TO_NAME: FileContentTypeToName = {
  [FileContentType.PDF]: 'pdf',
  [FileContentType.DOC]: 'word',
  [FileContentType.DOCX]: 'word',
  [FileContentType.JPEG]: 'image',
  [FileContentType.JPG]: 'image',
  [FileContentType.PNG]: 'image',
  [FileContentType.MP3]: 'voice',
  [FileContentType.MP4]: 'movie',
  [FileContentType.M4A]: 'voice',
  [FileContentType.MPEG]: 'voice',
  [FileContentType.WEBMA]: 'voice',
  [FileContentType.WEBMV]: 'movie',
  [FileContentType.ZIP]: 'file',
};

export const FIELD_OF_LAW_TO_LABEL: FieldOfLawToLabel = {
  [FieldOfLaw.ADMINISTRATIVE]: 'Administrative law',
  [FieldOfLaw.ANIMAL]: 'Animal law',
  [FieldOfLaw.ANTITRUST]: 'Antitrust law (or competition law)',
  [FieldOfLaw.ART]: 'Art law',
  [FieldOfLaw.BANKING]: 'Banking law',
  [FieldOfLaw.BANKRUPTCY]: 'Bankruptcy law',
  [FieldOfLaw.BUSINESS]: 'Business law (Commercial law)',
  [FieldOfLaw.CANON]: 'Canon law',
  [FieldOfLaw.CIVIL]: 'Civil law',
  [FieldOfLaw.COMMON]: 'Common law',
  [FieldOfLaw.COMMUNICATIONS]: 'Communications law',
  [FieldOfLaw.COMPANY]: 'Company law (Corporate law)',
  [FieldOfLaw.COMPUTER]: 'Computer law',
  [FieldOfLaw.CONSTITUTIONAL]: 'Constitutional law',
  [FieldOfLaw.CONSTRUCTION]: 'Construction law',
  [FieldOfLaw.CONSUMER]: 'Consumer law',
  [FieldOfLaw.CONTRACTS]: 'Contracts law',
  [FieldOfLaw.COPYRIGHT]: 'Copyright law',
  [FieldOfLaw.CRIMINAL]: 'Criminal law',
  [FieldOfLaw.CULTURAL]: 'Cultural law',
  [FieldOfLaw.CUSTOMS]: 'Customs law',
  [FieldOfLaw.DIVORCE]: 'Divorce law',
  [FieldOfLaw.DRUG]: 'Drug control law',
  [FieldOfLaw.EDUCATION]: 'Education law',
  [FieldOfLaw.EMPLOYMENT]: 'Employment law',
  [FieldOfLaw.ENERGY]: 'Energy law',
  [FieldOfLaw.ENTERTAINMENT]: 'Entertainment law',
  [FieldOfLaw.ENVIRONMENTAL]: 'Environmental law',
  [FieldOfLaw.FAMILY]: 'Family law',
  [FieldOfLaw.GAMING]: 'Gaming law',
  [FieldOfLaw.HEALTH]: 'Health and safety law',
  [FieldOfLaw.HEBRAIC]: 'Hebraic law',
  [FieldOfLaw.INHERITANCE]: 'Inheritance law (Estate law)',
  [FieldOfLaw.ISLAMIC]: 'Islamic law',
  [FieldOfLaw.INSURANCE]: 'Insurance law',
  [FieldOfLaw.IP]: 'Intellectual property law',
  [FieldOfLaw.INTERNATIONAL_PRIVATE]: 'International private law',
  [FieldOfLaw.INTERNATIONAL_PUBLIC]: 'International public law',
  [FieldOfLaw.INTERNET]: 'Internet law',
  [FieldOfLaw.LAND]: 'Land use & zoning law',
  [FieldOfLaw.LITIGATION]: 'Litigation law',
  [FieldOfLaw.MEDIA]: 'Media law',
  [FieldOfLaw.MEDICAL]: 'Medical law',
  [FieldOfLaw.M_AND_A]: 'Mergers & Acquisitions law',
  [FieldOfLaw.MILITARY]: 'Military law (Defense law)',
  [FieldOfLaw.NATIONALITY]: 'Nationality law',
  [FieldOfLaw.PRIVATE]: 'Private law',
  [FieldOfLaw.PROCEDURAL]: 'Procedural law',
  [FieldOfLaw.PRODUCT]: 'Product liability law',
  [FieldOfLaw.PROPERTY]: 'Property law',
  [FieldOfLaw.PUBLIC]: 'Public law',
  [FieldOfLaw.ROMAN]: 'Roman law',
  [FieldOfLaw.SCIENCE]: 'Science law',
  [FieldOfLaw.SECURITIES]: 'Securities law (Capital markets law)',
  [FieldOfLaw.SOCIAL]: 'Social Security law',
  [FieldOfLaw.SPACE]: 'Space law',
  [FieldOfLaw.SPORTS]: 'Sports law',
  [FieldOfLaw.TAX]: 'Tax law (Finance law)',
  [FieldOfLaw.TECHNOLOGY]: 'Technology law',
  [FieldOfLaw.TRANSPORT]: 'Transportation law',
  [FieldOfLaw.TRUST]: 'Trust law',
};

export const FIELD_OF_LAW_TO_TAG: FieldOfLawToTag = {
  [FieldOfLaw.ADMINISTRATIVE]: 'Administrative',
  [FieldOfLaw.ANIMAL]: 'Animal',
  [FieldOfLaw.ANTITRUST]: 'Antitrust',
  [FieldOfLaw.ART]: 'Art',
  [FieldOfLaw.BANKING]: 'Banking',
  [FieldOfLaw.BANKRUPTCY]: 'Bankruptcy',
  [FieldOfLaw.BUSINESS]: 'Business',
  [FieldOfLaw.CANON]: 'Canon',
  [FieldOfLaw.CIVIL]: 'Civil',
  [FieldOfLaw.COMMON]: 'Common',
  [FieldOfLaw.COMMUNICATIONS]: 'Communications',
  [FieldOfLaw.COMPANY]: 'Company',
  [FieldOfLaw.COMPUTER]: 'Computer',
  [FieldOfLaw.CONSTITUTIONAL]: 'Constitutional',
  [FieldOfLaw.CONSTRUCTION]: 'Construction',
  [FieldOfLaw.CONSUMER]: 'Consumer',
  [FieldOfLaw.CONTRACTS]: 'Contracts',
  [FieldOfLaw.COPYRIGHT]: 'Copyright',
  [FieldOfLaw.CRIMINAL]: 'Criminal',
  [FieldOfLaw.CULTURAL]: 'Cultural',
  [FieldOfLaw.CUSTOMS]: 'Customs',
  [FieldOfLaw.DIVORCE]: 'Divorce',
  [FieldOfLaw.DRUG]: 'Drug',
  [FieldOfLaw.EDUCATION]: 'Education',
  [FieldOfLaw.EMPLOYMENT]: 'Employment',
  [FieldOfLaw.ENERGY]: 'Energy',
  [FieldOfLaw.ENTERTAINMENT]: 'Entertainment',
  [FieldOfLaw.ENVIRONMENTAL]: 'Environmental',
  [FieldOfLaw.FAMILY]: 'Family',
  [FieldOfLaw.GAMING]: 'Gaming',
  [FieldOfLaw.HEALTH]: 'Health',
  [FieldOfLaw.HEBRAIC]: 'Hebraic',
  [FieldOfLaw.INHERITANCE]: 'Inheritance',
  [FieldOfLaw.ISLAMIC]: 'Islamic',
  [FieldOfLaw.INSURANCE]: 'Insurance',
  [FieldOfLaw.IP]: 'IP',
  [FieldOfLaw.INTERNATIONAL_PRIVATE]: 'International',
  [FieldOfLaw.INTERNATIONAL_PUBLIC]: 'International',
  [FieldOfLaw.INTERNET]: 'Internet',
  [FieldOfLaw.LAND]: 'Land',
  [FieldOfLaw.LITIGATION]: 'Litigation',
  [FieldOfLaw.MEDIA]: 'Media',
  [FieldOfLaw.MEDICAL]: 'Medical',
  [FieldOfLaw.M_AND_A]: 'M&A',
  [FieldOfLaw.MILITARY]: 'Military',
  [FieldOfLaw.NATIONALITY]: 'Nationality',
  [FieldOfLaw.PRIVATE]: 'Private',
  [FieldOfLaw.PROCEDURAL]: 'Procedural',
  [FieldOfLaw.PRODUCT]: 'Product',
  [FieldOfLaw.PROPERTY]: 'Property',
  [FieldOfLaw.PUBLIC]: 'Public',
  [FieldOfLaw.ROMAN]: 'Roman',
  [FieldOfLaw.SCIENCE]: 'Science',
  [FieldOfLaw.SECURITIES]: 'Securities',
  [FieldOfLaw.SOCIAL]: 'Social',
  [FieldOfLaw.SPACE]: 'Space',
  [FieldOfLaw.SPORTS]: 'Sports',
  [FieldOfLaw.TAX]: 'Tax',
  [FieldOfLaw.TECHNOLOGY]: 'Technology',
  [FieldOfLaw.TRANSPORT]: 'Transport',
  [FieldOfLaw.TRUST]: 'Trust',
};

export const FIELD_OF_LAW_OPTIONS = sortBy(
  Object.entries(FIELD_OF_LAW_TO_LABEL).map(([value, label]) => ({
    value,
    label,
  })),
  (fieldOfLaw) => deburr(fieldOfLaw.label)
);

export const URL_REGEX = /http(s)?:\/\/?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const MAX_DESCRIPTION_LENGTH = 16777216;

export const SORT_OPTIONS = [
  {
    name: 'Date – newest first',
    value: '-created_at',
  },
  {
    name: 'Date – oldest first',
    value: 'created_at',
  },
  {
    name: 'A–Z',
    value: 'title',
  },
  {
    name: 'Z-A',
    value: '-title',
  },
  {
    name: 'Recently updated',
    value: '-updated_at',
  },
];

export const COUNTRY_OPTIONS = sortBy(
  Object.entries(Country).map(([label, value]) => ({
    value,
    label,
  })),
  (country) => deburr(country.label)
);

type CountryByValue = {
  [key in Country]: { value: Country; label: string };
};

export const COUNTRY_BY_VALUE: CountryByValue = COUNTRY_OPTIONS.reduce((result: any, country) => {
  result[country.value] = country;
  return result;
}, {});

export const POSITION = [
  'Lawyer',
  'Attorney',
  'Counselor',
  'Barrister',
  'Solicitor',
  'Mediator',
  'Arbitrator',
  'Notary',
  'Jurist',
  'Legal officer',
  'Judge',
  'Prosecutor',
  'Clerk',
  'Court employee',
  'State employee',
  'Professor',
  'Assistant',
  'Student',
  'Trainee',
  'Paralegal',
  'Secretary',
  'Other (please specify)',
];

export const POSITION_OPTIONS = POSITION.map((value) => ({
  value,
  label: value,
}));

export const POSITION_BY_VALUE = POSITION_OPTIONS.reduce((result: any, position) => {
  result[position.value] = position;
  return result;
}, {});

export const CODE_ACCESS_OPTIONS = [
  {
    label: 'Read',
    value: CodeAccess.READ,
  },
  {
    label: 'Annotate',
    value: CodeAccess.ANNOTATE,
  },
  {
    label: 'Organize',
    value: CodeAccess.ORGANIZE,
  },
];

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}
