import { FieldOfLaw } from '../FieldOfLaw';
import { Country } from '../Country';
import { Onboarding } from './Onboarding';

export interface IFieldOfLaw {
  id: number;
  name: string;
}

export interface IUserBase {
  id: IUser['id'];
  fullName: string;
  avatar: IUser['avatar'];
}

export interface IOwnerInfo {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  type: UserType;
}

export enum UserType {
  ADMIN = 'admin',
  USER = 'user',
  TMP_USER = 'tmp_user',
  ARCHIVED = 'archived',
}

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string | null;
  country: Country;
  position: string;
  type: UserType;
  isDeleted: boolean;
  fieldsOfLaw: FieldOfLaw[];
  hasAcceptedTerms: boolean;
  onboardings: Onboarding[];
  has2fa: boolean;
}
