import { connect, send, disconnect } from '@giantmachines/redux-websocket';
import { ISocketSubscribe, ISubscriptionData } from '@codex/shared';

export enum WsPrefix {
  CODE = 'code',
  USER = 'user',
}

type SubscribeType = {
  event: ISocketSubscribe['event'];
  data?: Omit<ISubscriptionData, 'headers'>;
  prefix: WsPrefix;
};

const getToken = () => {
  const store = require('store').default;
  const {
    auth: { token },
  } = store.getState();

  return token;
};

const getWsUrl = () =>
  `${process.env.REACT_APP_WS_URL || 'ws://localhost:5001/ws'}?token=${getToken()}`;

const subscribeConnection = ({ event, data, prefix }: SubscribeType) =>
  send(
    {
      event,
      data: {
        ...(data && { ...data }),
        headers: {
          authorization: getToken(),
        },
      },
    },
    prefix
  );

export const subscribeUser = (props: Omit<SubscribeType, 'prefix'>) =>
  subscribeConnection({ ...props, prefix: WsPrefix.USER });
export const subscribeCode = (props: Omit<SubscribeType, 'prefix'>) =>
  subscribeConnection({ ...props, prefix: WsPrefix.CODE });

export const connectUser = () => connect(getWsUrl(), WsPrefix.USER);
export const connectCode = () => connect(getWsUrl(), WsPrefix.CODE);

export const disconnectUser = () => disconnect(WsPrefix.USER);
export const disconnectCode = () => disconnect(WsPrefix.CODE);
