import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICode, IOrderBy } from '@codex/shared/interfaces';

import * as CodesAPI from 'api/codes';
import { AppThunk } from 'store';
import { SORT_OPTIONS } from '../../constants';
import { logout } from '../auth/authSlice';

type SearchParams = {
  title?: string;
} & IOrderBy;

export interface CodesState {
  data: ICode[] | null;
  isLoading: boolean;
  error: string | null;
  searchParams: SearchParams;
}

const initialState: CodesState = {
  data: null,
  isLoading: false,
  error: null,
  searchParams: {
    orderBy: SORT_OPTIONS[0].value,
  },
};

const slice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    setCodesStart(state) {
      state.isLoading = true;
    },
    setCodesSuccess(state, { payload }: PayloadAction<CodesAPI.CodesResponse>) {
      state.data = payload.data;
      state.isLoading = false;
    },
    setCodesFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    setSearchParams(state, { payload }: PayloadAction<Partial<SearchParams>>) {
      Object.assign(state.searchParams, payload);
    },
    resetSearchParams(state) {
      state.searchParams = { ...initialState.searchParams };
    },
    deleteCode(state, { payload: codeId }: PayloadAction<number>) {
      if (!state.data) {
        return;
      }

      state.data.splice(
        state.data.findIndex((code) => code.id === codeId),
        1
      );
    },
    updateCode(state, { payload }: PayloadAction<ICode>) {
      if (!state.data) {
        return;
      }

      const index = state.data.findIndex((code) => code.id === payload.id);
      state.data[index] = payload;
    },
  },
  extraReducers: {
    [logout.toString()]: () => {
      return { ...initialState };
    },
  },
});

export const { setSearchParams, resetSearchParams } = slice.actions;

export default slice.reducer;

export const getCodes = (params: SearchParams): AppThunk => async (dispatch) => {
  const { setCodesStart, setCodesSuccess, setCodesFailure } = slice.actions;

  try {
    dispatch(setCodesStart());
    const codes = await CodesAPI.getCodes(params);
    dispatch(setCodesSuccess(codes));
  } catch (error) {
    dispatch(setCodesFailure(error));
  }
};

export const deleteCode = (codeId: number): AppThunk => async (dispatch) => {
  const { deleteCode } = slice.actions;

  try {
    dispatch(deleteCode(codeId));
    await CodesAPI.deleteCode(codeId);
  } catch (error) {
    console.error(error);
  }
};

export const updateCode = (codeId: number, data: Partial<Omit<ICode, 'id'>>): AppThunk => async (
  dispatch
) => {
  const { updateCode } = slice.actions;

  try {
    const code = await CodesAPI.updateCode(codeId, data);
    dispatch(updateCode(code));
  } catch (error) {
    console.error(error);
  }
};
