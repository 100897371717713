import styled from 'styled-components/macro';

export const ModalTitle = styled.h3`
  padding: 0 24px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.16px;
  color: var(--color-gray-1500);
  line-height: 70px;
`;

export default ModalTitle;
