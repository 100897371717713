import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IBookmark,
  IChange,
  ICode,
  ICodeChanges,
  IDocument,
  IError,
  IGeneralNote,
  IGeneralNoteAttachment,
  ISelection,
  ISelectionAttachment,
  ISelectionComment,
  IUser,
  ReviewCodeUpdate,
  SelectionType,
  SocketEventAction,
  SocketEventType,
} from '@codex/shared/interfaces';
import { IUpdateCodeAccessResponse } from '@codex/shared/interfaces/endpoints/codes/users/updateCodeAccess';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import deburr from 'lodash/deburr';

import * as CodesAPI from 'api/codes';
import * as SelectionsAPI from 'api/selections';
import * as GeneralNotesAPI from 'api/generalNotes';
import * as BookmarksAPI from 'api/bookmarks';
import { AppThunk } from 'store';
import debounceAction from 'utils/debounceAction';
import { WebsocketCodeActions } from 'features/wsConnection/types';
import { setActiveResult } from 'features/codeEditSearch/codeEditSearchSlice';
import { logout } from 'features/auth/authSlice';
import { createWeboscketListener } from 'features/wsConnection/utils';
import { SupportedMediaNoteFormat } from './utils';
import { ShareAnnotationModalPayload, ShareAnnotationPayload, ShareGeneralNotePayload } from './types';

export enum LeftPanelType {
  TABLE_OF_CONTENTS = 'table-of-contents',
  BOOKMARKS = 'bookmarks',
  PAGES = 'pages',
}

export enum RightPanelType {
  CODE_UPDATE = 'code-update',
  GENERAL_NOTES = 'general-notes',
  ANNOTATIONS = 'annotations',
}

const INITIAL_SCALE = 2;
const INITIAL_PAGE_NUMBER = 1;
const INITIAL_LEFT_PANEL = LeftPanelType.TABLE_OF_CONTENTS;
const INITIAL_RIGHT_PANEL = RightPanelType.ANNOTATIONS;
let TEMPORARY_SELECTION_ID = -1;

export type ITemporarySelection = Pick<
  ISelection,
  | 'id'
  | 'documentId'
  | 'text'
  | 'color'
  | 'startPage'
  | 'startElement'
  | 'startCharacter'
  | 'endPage'
  | 'endElement'
  | 'endCharacter'
  | 'data'
  | 'isBookmark'
  | 'isAnnotation'
  | 'name'
>;
export type ISelections = Array<ITemporarySelection | ISelection>;
type IWsConnectionPayload = IUser &
  ISelection &
  IBookmark &
  IGeneralNote &
  IGeneralNoteAttachment &
  IDocument &
  IUpdateCodeAccessResponse &
  (ISelectionComment | ISelectionAttachment);

interface CodeEditState {
  code: {
    isLoading: boolean;
    data: ICode | null;
    error: string | null;
  };
  codeWithUpdates: {
    isLoading: boolean;
    data: ICode | null;
    error: string | null;
  };
  selections: {
    isLoading: boolean;
    data: ISelections;
    error: string | null;
  };
  changes: {
    isLoading: boolean;
    data: IChange[];
    error: string | null;
  };
  codeChanges: {
    isLoading: boolean;
    data: ICodeChanges;
    error: string | null;
  };
  generalNotes: {
    isLoading: boolean;
    data: IGeneralNote[];
    error: string | null;
  };
  bookmarks: {
    isLoading: boolean;
    data: IBookmark[];
    error: string | null;
  };
  toolbar: {
    scale: number;
    pageNumber: number;
  };
  users: {
    isLoading: boolean;
    data: IUser[];
    error: string | null;
  };
  review: {
    error: IError | null;
  }
  leftPanel: LeftPanelType | null;
  rightPanel: RightPanelType | null;
  scale: number;
  pageNumber: number;
  activeSelectionId: number | null;
  activeDocumentId: number | null;
  newDocumentId: number | null;
  preventDeactivation: boolean;
  activeCommentForm: number | null;
  activeAttachmentForm: number | null;
  activeMediaNoteForm: {
    type: SupportedMediaNoteFormat;
    selectionId: number;
  } | null;
  annotationToShare: ShareAnnotationModalPayload | null;
  annotationSharedSuccessfully: boolean | null;
  generalNoteToShare: IGeneralNote | null;
  generalNoteSharedSuccessfully: boolean | null;
}

const initialState: CodeEditState = {
  code: {
    isLoading: false,
    data: null,
    error: null,
  },
  codeWithUpdates: {
    isLoading: false,
    data: null,
    error: null,
  },

  selections: {
    isLoading: false,
    data: [],
    error: null,
  },
  changes: {
    isLoading: false,
    data: [],
    error: null,
  },
  codeChanges: {
    isLoading: false,
    data: {},
    error: null,
  },
  generalNotes: {
    isLoading: false,
    data: [],
    error: null,
  },
  bookmarks: {
    isLoading: false,
    data: [],
    error: null,
  },
  toolbar: {
    scale: INITIAL_SCALE,
    pageNumber: INITIAL_PAGE_NUMBER,
  },
  users: {
    isLoading: false,
    data: [],
    error: null,
  },
  review: {
    error: null,
  },
  leftPanel: INITIAL_LEFT_PANEL,
  rightPanel: INITIAL_RIGHT_PANEL,
  scale: INITIAL_SCALE,
  pageNumber: INITIAL_PAGE_NUMBER,
  activeSelectionId: null,
  activeDocumentId: null,
  newDocumentId: null,
  preventDeactivation: false,
  activeCommentForm: null,
  activeAttachmentForm: null,
  activeMediaNoteForm: null,
  annotationToShare: null,
  annotationSharedSuccessfully: null,
  generalNoteToShare: null,
  generalNoteSharedSuccessfully: null,
};

const joinUserHelper = ({ users }: CodeEditState, { payload }: PayloadAction<IUser>) => {
  users.data.push(payload);
};

const removeUserHelper = ({ users }: CodeEditState, { payload }: PayloadAction<IUser>) => {
  users.data = users.data.filter((user) => user.id !== payload.id);
};

const selectionCreateSuccessHelper = (
  state: CodeEditState,
  { payload }: PayloadAction<ISelection>
) => {
  const shouldAddSelection = !state.selections.data.find(
    (selection) => selection.id === payload.id
  );

  if (payload.documentId === state.activeDocumentId && shouldAddSelection) {
    const document = state.code!.data!.documents!.find(
      (document) => document.id === payload.documentId
    );

    state.selections.data = sortBy(
      [...state.selections.data, payload],
      ['startPage', 'startElement', 'startCharacter']
    );
    state.code!.data!.totalSelectionsCount! += 1;

    if (document) {
      document.totalSelectionsCount += 1;
    }
  }
};

const deleteSelectionHelper = (
  state: CodeEditState,
  { payload: { id } }: PayloadAction<{ id: number }>
) => {
  const selection = state.selections.data.find((selection) => selection.id === id);

  if (!selection) return;
  if (id === state.activeSelectionId) state.activeSelectionId = null;

  state.selections.data = state.selections.data.filter((selection) => selection.id !== id);

  state.code!.data!.totalSelectionsCount! -= 1;

  const document = state.code?.data?.documents?.find(
    (document) => document.id === selection.documentId
  );

  if (document) document.totalSelectionsCount -= 1;
};

const addSelectionDataItemHelper = (
  { selections }: CodeEditState,
  { payload }: PayloadAction<ISelectionComment | ISelectionAttachment>
) => {
  const selection = selections.data.find((selection) => selection.id === payload.selectionId);

  if (!selection) return;

  const shouldAddDataItem = !selection.data.find((item) => item.id === payload.id);

  if (shouldAddDataItem) selection.data.push(payload);
};

const changeSelectionTypeHelper = (
  { selections }: CodeEditState,
  { payload }: PayloadAction<{ selectionId: number, isAnnotation: boolean }>
) => {
  const selection = selections.data.find((selection) => selection.id === payload.selectionId);

  if (!selection) return;

  selection.isAnnotation = payload.isAnnotation;
}

const deleteSelectionDataItemHelper = (
  { selections }: CodeEditState,
  { payload: { selectionId, id: itemId } }: PayloadAction<{ selectionId: number; id: number }>
) => {
  const selection = selections.data.find((selection) => selection.id === selectionId);

  if (!selection) return;

  selection.data = selection.data.filter((dataItem) => dataItem.id !== itemId);
};

const updateSelectionDataItemHelper = (
  { selections }: CodeEditState,
  {
    payload,
  }: PayloadAction<
    {
      selectionId: number;
      id: number;
    } & SelectionsAPI.UpdateCommentData
  >
) => {
  const selection = selections.data.find((selection) => selection.id === payload.selectionId);

  if (!selection) return;

  selection.data = selection.data.map((item) =>
    item.id === payload.id ? { ...item, ...payload } : item
  );
};

const updateGeneralNoteDataItemHelper = (
  { generalNotes }: CodeEditState,
  {
    payload,
  }: PayloadAction<
    {
      noteId: number;
      id: number;
    } & GeneralNotesAPI.UpdateAttachmentData
  >
) => {
  const note = generalNotes.data.find((note) => note.id === payload.noteId);

  if (!note) return;

  note.data = note.data.map((item) => (item.id === payload.id ? { ...item, ...payload } : item));
};

const addBookmarksHelper = (
  { bookmarks }: CodeEditState,
  { payload }: PayloadAction<IBookmark>
) => {
  const shouldAddBookmark = !bookmarks.data.find((bookmark) => bookmark.id === payload.id);

  if (shouldAddBookmark)
    bookmarks.data = sortBy(
      [...bookmarks.data, payload],
      ['page', (bookmark: IBookmark) => deburr(bookmark.name.toLowerCase())]
    );
};

const deleteBookmarkHelper = (
  { bookmarks }: CodeEditState,
  { payload: { id } }: PayloadAction<{ id: number }>
) => {
  bookmarks.data = bookmarks.data.filter((item) => item.id !== id);
};

const updateBookmarkHelper = (
  { bookmarks }: CodeEditState,
  {
    payload,
  }: PayloadAction<
    {
      id: number;
    } & BookmarksAPI.UpdateBookmarkData
  >
) => {
  bookmarks.data = bookmarks.data.map((bookmark) =>
    bookmark.id === payload.id ? { ...bookmark, ...payload } : bookmark
  );
};

const addGeneralNoteHelper = (
  { generalNotes }: CodeEditState,
  { payload }: PayloadAction<IGeneralNote>
) => {
  const shouldAddgeneralNote = !generalNotes.data.find(
    (generalNote) => generalNote.id === payload.id
  );

  if (shouldAddgeneralNote) generalNotes.data.push(payload);
};

const deleteGeneralNoteHelper = (
  { generalNotes }: CodeEditState,
  { payload: { id } }: PayloadAction<{ id: number }>
) => {
  generalNotes.data = generalNotes.data.filter((item) => item.id !== id);
};

const updateGeneralNoteHelper = (
  { generalNotes }: CodeEditState,
  {
    payload,
  }: PayloadAction<
    {
      id: number;
    } & GeneralNotesAPI.UpdateGeneralNoteData
  >
) => {
  generalNotes.data = generalNotes.data.map((generalNote) =>
    generalNote.id === payload.id ? { ...generalNote, ...payload } : generalNote
  );
};

const addGeneralNoteDataItemHelper = (
  { generalNotes }: CodeEditState,
  { payload }: PayloadAction<IGeneralNoteAttachment>
) => {
  const generalNote = generalNotes.data.find((generalNote) => generalNote.id === payload.noteId);

  if (!generalNote) {
    return;
  }

  const shouldAddGeneralNoteDataItem = !generalNote.data.find((item) => item.id === payload.id);

  if (shouldAddGeneralNoteDataItem) generalNote.data.push(payload);
};

const deleteGeneralNoteDataItemHelper = (
  { generalNotes }: CodeEditState,
  { payload: { id: attachmentId, noteId } }: PayloadAction<{ id: number; noteId: number }>
) => {
  const generalNote = generalNotes.data.find((generalNote) => generalNote.id === noteId);

  if (!generalNote) {
    return;
  }

  generalNote.data = generalNote.data.filter((item) => item.id !== attachmentId);
};

const slice = createSlice({
  name: 'codeEdit',
  initialState,
  reducers: {
    setCodeStart({ code }) {
      code.isLoading = true;
      code.data = null;
    },
    setCodeSuccess({ code }, { payload }: PayloadAction<ICode>) {
      code.isLoading = false;
      code.data = payload;
    },
    setCodeFailure({ code }, { payload }: PayloadAction<string>) {
      code.isLoading = false;
      code.error = payload;
    },
    setCodeWithUpdatesStart({ codeWithUpdates }) {
      codeWithUpdates.isLoading = true;
      codeWithUpdates.data = null;
    },
    setCodeWithUpdatesSuccess({ codeWithUpdates }, { payload }: PayloadAction<ICode>) {
      codeWithUpdates.isLoading = false;
      codeWithUpdates.data = payload;
    },
    setCodeWithUpdatesFailure({ codeWithUpdates }, { payload }: PayloadAction<string>) {
      codeWithUpdates.isLoading = false;
      codeWithUpdates.error = payload;
    },
    resetEditCode() {
      return { ...initialState };
    },
    setSelectionsStart({ selections }) {
      selections.isLoading = true;
      selections.data = [];
    },
    setSelectionsSuccess({ selections }, { payload }: PayloadAction<ISelection[]>) {
      selections.isLoading = false;
      selections.data = payload;
    },
    setSelectionsFailure({ selections }, { payload }: PayloadAction<string>) {
      selections.isLoading = false;
      selections.error = payload;
    },
    setChangesStart({ changes }) {
      changes.isLoading = true;
      changes.data = [];
    },
    setChangesSuccess({ changes }, { payload }: PayloadAction<IChange[]>) {
      changes.isLoading = false;
      changes.data = payload.map((change, index) => ({ ...change, id: index + 1 }));
    },
    setChangesFailure({ changes }, { payload }: PayloadAction<string>) {
      changes.isLoading = false;
      changes.error = payload;
    },    
    setCodeChangesStart({ codeChanges }) {
      codeChanges.isLoading = true;
      codeChanges.data = {};
    },
    setCodeChangesSuccess({ codeChanges }, { payload }: PayloadAction<ICodeChanges>) {
      codeChanges.isLoading = false;
      codeChanges.data = payload;
    },
    setCodeChangesFailure({ codeChanges }, { payload }: PayloadAction<string>) {
      codeChanges.isLoading = false;
      codeChanges.error = payload;
    },
    setGeneralNotesStart({ generalNotes }) {
      generalNotes.isLoading = true;
      generalNotes.data = [];
    },
    setGeneralNotesSuccess({ generalNotes }, { payload }: PayloadAction<IGeneralNote[]>) {
      generalNotes.isLoading = false;
      generalNotes.data = payload;
    },
    setGeneralNotesFailure({ generalNotes }, { payload }: PayloadAction<string>) {
      generalNotes.isLoading = false;
      generalNotes.error = payload;
    },
    setBookmarksStart({ bookmarks }) {
      bookmarks.isLoading = true;
      bookmarks.data = [];
    },
    setBookmarksSuccess({ bookmarks }, { payload }: PayloadAction<IBookmark[]>) {
      bookmarks.isLoading = false;
      bookmarks.data = payload;
    },
    setBookmarksFailure({ bookmarks }, { payload }: PayloadAction<string>) {
      bookmarks.isLoading = false;
      bookmarks.error = payload;
    },
    setSelectionCreateSuccess: selectionCreateSuccessHelper,
    setSelectionUpdateSuccess(
      { selections, code },
      {
        payload: { keys, data },
      }: PayloadAction<{
        keys: Array<keyof SelectionsAPI.UpdateSelectionData>;
        data: any;
      }>
    ) {
      const index = selections.data.findIndex((selection) => selection.id === data.id);
      const selection = selections.data[index];

      keys.forEach((key) => {
        selection[key] = data[key];
      });
    },
    setScale(state, { payload }: PayloadAction<number>) {
      state.scale = payload;
    },
    setPageNumber(state, { payload }: PayloadAction<number>) {
      state.pageNumber = payload;
    },
    setLeftPanel(state, { payload }: PayloadAction<LeftPanelType | null>) {
      state.leftPanel = payload;
    },
    setRightPanel(state, { payload }: PayloadAction<RightPanelType | null>) {
      state.rightPanel = payload;
    },
    setActiveDocumentId(state, { payload }: PayloadAction<number>) {
      state.activeDocumentId = payload;
      state.scale = initialState.scale;
      state.pageNumber = initialState.pageNumber;

      state.toolbar.scale = initialState.toolbar.scale;
      state.toolbar.pageNumber = initialState.toolbar.pageNumber;
    },
    setNewDocumentId(state, { payload }: PayloadAction<number>) {
      state.newDocumentId = payload;
    },
    setToolbarScale({ toolbar }, { payload }: PayloadAction<number>) {
      toolbar.scale = payload;
    },
    setToolbarPageNumber({ toolbar }, { payload }: PayloadAction<number>) {
      toolbar.pageNumber = payload;
    },
    addTemporarySelection(
      state,
      {
        payload: { selection, type, mediaNoteType },
      }: PayloadAction<{
        selection: Omit<ITemporarySelection, 'id' | 'data'>;
        type: SelectionType;
        mediaNoteType?: SupportedMediaNoteFormat;
      }>
    ) {
      const id = TEMPORARY_SELECTION_ID--;
      const document = state.code!.data!.documents!.find(
        (document) => document.id === selection.documentId
      );

      state.selections.data = sortBy(
        [...state.selections.data, { ...selection, id, data: [] }],
        ['startPage', 'startElement', 'startCharacter']
      );
      state.activeSelectionId = id;

      state.code!.data!.totalSelectionsCount! += 1;

      if (document) {
        document.totalSelectionsCount += 1;
      }

      if (type === SelectionType.COMMENT) {
        state.activeCommentForm = id;
      } else if (type === SelectionType.ATTACHMENT) {
        if (mediaNoteType) {
          state.activeMediaNoteForm = {
            type: mediaNoteType,
            selectionId: id,
          };
        } else {
          state.activeAttachmentForm = id;
        }
      }
    },
    removeTemporarySelection(state, { payload }: PayloadAction<number>) {
      state.selections.data = state.selections.data.filter((selection) => selection.id !== payload);
    },
    setActiveSelectionId(state, { payload }: PayloadAction<number | null>) {
      state.activeSelectionId = payload;
    },
    setPreventDeactivation(state, { payload }: PayloadAction<boolean>) {
      state.preventDeactivation = payload;
    },
    setActiveCommentForm(state, { payload }: PayloadAction<number | null>) {
      state.activeCommentForm = payload;
    },
    setActiveAttachmentForm(state, { payload }: PayloadAction<number | null>) {
      state.activeAttachmentForm = payload;
    },
    setActiveMediaNoteForm(
      state,
      { payload }: PayloadAction<{ type: SupportedMediaNoteFormat; selectionId: number } | null>
    ) {
      state.activeMediaNoteForm = payload;
    },
    setAddNewColor({ code }, { payload }: PayloadAction<string[]>) {
      if (code.data) code.data.colors = payload;
    },
    setAnnotationToShare(state, { payload }: PayloadAction<ShareAnnotationModalPayload | null>) {
      state.annotationSharedSuccessfully = null;
      state.annotationToShare = payload;
    },
    shareAnnotationSuccess(state) {
      state.annotationSharedSuccessfully = true;
    },
    shareAnnotationFail(state) {
      state.annotationSharedSuccessfully = false;
    },
    setGeneralNoteToShare(state, { payload }: PayloadAction<IGeneralNote | null>) {
      state.generalNoteSharedSuccessfully = null;
      state.generalNoteToShare = payload;
    },
    shareGeneralNoteSuccess(state) {
      state.generalNoteSharedSuccessfully = true;
    },
    shareGeneralNoteFail(state) {
      state.generalNoteSharedSuccessfully = false;
    },
    updateCodeAfterReview(state, { payload }: PayloadAction<Partial<ICode>>) {
      if (!state.code.data) {
        return;
      }
      state.code.data = {
        ...state.code.data,
        ...payload,
      };
      state.changes.data = [];
    },
    setReviewError(state, { payload }: PayloadAction<IError | null>) {
      state.review.error = payload;
    },
    deleteSelectionDataItem: deleteSelectionDataItemHelper,
    updateSelectionDataItem: updateSelectionDataItemHelper,
    deleteSelection: deleteSelectionHelper,
    addSelectionDataItem: addSelectionDataItemHelper,
    changeSelectionType: changeSelectionTypeHelper,
    addGeneralNote: addGeneralNoteHelper,
    deleteGeneralNote: deleteGeneralNoteHelper,
    updateGeneralNote: updateGeneralNoteHelper,
    addGeneralNoteDataItem: addGeneralNoteDataItemHelper,
    deleteGeneralNoteDataItem: deleteGeneralNoteDataItemHelper,
    updateGeneralNoteDataItem: updateGeneralNoteDataItemHelper,
    addBookmark: addBookmarksHelper,
    deleteBookmark: deleteBookmarkHelper,
    updateBookmark: updateBookmarkHelper,
  },
  extraReducers: {
    [setActiveResult.toString()]: (state, { payload }) => {
      const pageNumber = payload.startPage + 1;

      if (pageNumber === state.pageNumber) {
        return;
      }

      state.pageNumber = pageNumber;
      state.toolbar.pageNumber = pageNumber;
    },
    [logout.toString()]: () => {
      return { ...initialState };
    },
    [WebsocketCodeActions.MESSAGE]: createWeboscketListener<CodeEditState, IWsConnectionPayload>({
      [SocketEventType.USER]: {
        [SocketEventAction.JOIN]: joinUserHelper,
        [SocketEventAction.LEAVE]: removeUserHelper,
      },
      [SocketEventType.SELECTION]: {
        [SocketEventAction.CREATE]: selectionCreateSuccessHelper,
        [SocketEventAction.DELETE]: deleteSelectionHelper,
      },
      [SocketEventType.SELECTION_COMMENT]: {
        [SocketEventAction.CREATE]: addSelectionDataItemHelper,
        [SocketEventAction.UPDATE]: updateSelectionDataItemHelper,
        [SocketEventAction.DELETE]: deleteSelectionDataItemHelper,
      },
      [SocketEventType.SELECTION_ATTACHMENT]: {
        [SocketEventAction.CREATE]: addSelectionDataItemHelper,
        [SocketEventAction.UPDATE]: updateSelectionDataItemHelper,
        [SocketEventAction.DELETE]: deleteSelectionDataItemHelper,
      },
      [SocketEventType.BOOKMARK]: {
        [SocketEventAction.CREATE]: addBookmarksHelper,
        [SocketEventAction.DELETE]: deleteBookmarkHelper,
        [SocketEventAction.UPDATE]: updateBookmarkHelper,
      },
      [SocketEventType.NOTE]: {
        [SocketEventAction.CREATE]: addGeneralNoteHelper,
        [SocketEventAction.DELETE]: deleteGeneralNoteHelper,
        [SocketEventAction.UPDATE]: updateGeneralNoteHelper,
      },
      [SocketEventType.NOTE_ATTACHMENT]: {
        [SocketEventAction.CREATE]: addGeneralNoteDataItemHelper,
        [SocketEventAction.DELETE]: deleteGeneralNoteDataItemHelper,
      },
      [SocketEventType.DOCUMENT]: {
        [SocketEventAction.CREATE]: (
          { code }: CodeEditState,
          { payload }: PayloadAction<IDocument>
        ) => {
          if (code.data) code.data.documents.push(payload);
        },
        [SocketEventAction.UPDATE]: (
          { code }: CodeEditState,
          { payload }: PayloadAction<IDocument>
        ) => {
          if (code.data)
            code.data.documents = code.data.documents.map((document: IDocument) =>
              document.id === payload.id ? { ...document, ...payload } : document
            );
        },
        [SocketEventAction.DELETE]: (
          { code }: CodeEditState,
          { payload }: PayloadAction<IDocument>
        ) => {
          if (code.data)
            code.data.documents = code.data.documents.filter(
              (document: IDocument) => document.id !== payload.id
            );
        },
      },
      [SocketEventType.ACCESS]: {
        [SocketEventAction.UPDATE]: (
          { code }: CodeEditState,
          { payload }: PayloadAction<IUpdateCodeAccessResponse>
        ) => {
          if (code.data) {
            if (code.data.user.id === payload.id) code.data.user.access = payload.access;
          }
        },
        [SocketEventAction.DELETE]: (
          { code }: CodeEditState,
          { payload }: PayloadAction<{ id: number }>
        ) => {
          if (code.data) {
            if (code.data.user.id === payload.id) code.data = null;
          }
        },
      },
    }),
  },
});

export const {
  resetEditCode,
  setSelectionCreateSuccess,
  setLeftPanel,
  setRightPanel,
  setActiveDocumentId,
  setNewDocumentId,
  setScale,
  setToolbarScale,
  setToolbarPageNumber,
  addTemporarySelection,
  setActiveSelectionId,
  setPreventDeactivation,
  setActiveCommentForm,
  setActiveAttachmentForm,
  removeTemporarySelection,
  setActiveMediaNoteForm,
  setAnnotationToShare,
  shareAnnotationSuccess,
  shareAnnotationFail,
  setGeneralNoteToShare,
  shareGeneralNoteSuccess,
  shareGeneralNoteFail,
  updateCodeAfterReview,
  setReviewError,
} = slice.actions;

export default slice.reducer;

export const getCode = (codeId: number): AppThunk => async (dispatch) => {
  const { setCodeStart, setCodeSuccess, setCodeFailure } = slice.actions;

  try {
    dispatch(setCodeStart());

    const code = await CodesAPI.getCode(codeId);

    dispatch(setCodeSuccess(code));
  } catch (error) {
    dispatch(setCodeFailure('Code does not exist'));
  }
};

export const getCodeWithUpdates = (codeId: number): AppThunk => async (dispatch) => {
  const { setCodeWithUpdatesStart, setCodeWithUpdatesSuccess, setCodeWithUpdatesFailure } = slice.actions;

  try {
    dispatch(setCodeWithUpdatesStart());

    const code = await CodesAPI.getCodeWithUpdates(codeId);

    dispatch(setCodeWithUpdatesSuccess(code));
  } catch (error) {
    dispatch(setCodeWithUpdatesFailure('Code does not exist'));
  }
};

export const getSelections = (documentId: number, params?: Object): AppThunk => async (
  dispatch
) => {
  const { setSelectionsStart, setSelectionsSuccess, setSelectionsFailure } = slice.actions;

  try {
    dispatch(setSelectionsStart());
    const selections = await SelectionsAPI.getSelections(documentId, params);
    dispatch(setSelectionsSuccess(selections));
  } catch (error) {
    dispatch(setSelectionsFailure(error));
  }
};

export const getChanges = (documentId: number, params?: Object): AppThunk => async (
  dispatch
) => {
  const { setChangesStart, setChangesSuccess, setChangesFailure } = slice.actions;

  try {
    dispatch(setChangesStart());
    const selections = await SelectionsAPI.getChanges(documentId, params);
    dispatch(setChangesSuccess(selections));
  } catch (error) {
    dispatch(setChangesFailure(error));
  }
};

export const getCodeChanges = (codeId: number): AppThunk => async (
  dispatch
) => {
  const { setCodeChangesStart, setCodeChangesSuccess, setCodeChangesFailure } = slice.actions;

  try {
    dispatch(setCodeChangesStart());
    const changes = await CodesAPI.getCodeChanges(codeId);
    dispatch(setCodeChangesSuccess(changes.data));
  } catch (error) {
    dispatch(setCodeChangesFailure(error));
  }
};

export const addSelection = (data: SelectionsAPI.CreateSelectionData): AppThunk => async (
  dispatch
) => {
  try {
    const selection = await SelectionsAPI.createSelection(data);
    dispatch(setSelectionCreateSuccess(selection));
    dispatch(setActiveSelectionId(selection.id));
  } catch (error) {
    console.error(error);
  }
};

export const saveTemporarySelection = (
  selectionData: ITemporarySelection,
  type: SelectionType,
  data?:
    | Omit<SelectionsAPI.CreateCommentData, 'selectionId'>
    | Omit<SelectionsAPI.CreateAttachmentData, 'selectionId'>
): AppThunk => async (dispatch) => {
  const { addSelectionDataItem } = slice.actions;

  try {
    const selection = await SelectionsAPI.createSelection(
      pick(selectionData, [
        'documentId',
        'text',
        'color',
        'startPage',
        'startElement',
        'startCharacter',
        'endPage',
        'endElement',
        'endCharacter',
        'isBookmark',
        'isAnnotation',
        'name',
      ])
    );
    let item;

    if (type === SelectionType.COMMENT) {
      item = await SelectionsAPI.createComment({
        ...data,
        selectionId: selection.id,
      } as SelectionsAPI.CreateCommentData);
    } else if (type === SelectionType.ATTACHMENT) {
      item = await SelectionsAPI.createAttachment({
        ...data,
        selectionId: selection.id,
      } as SelectionsAPI.CreateAttachmentData);
    }

    if (item) {
      dispatch(setSelectionCreateSuccess(selection));
      dispatch(setActiveSelectionId(selection.id));
      dispatch(addSelectionDataItem(item));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateSelection = (
  selectionId: number,
  data: Partial<SelectionsAPI.CreateSelectionData>
): AppThunk => async (dispatch) => {
  const { setSelectionUpdateSuccess } = slice.actions;

  try {
    const selection = await SelectionsAPI.updateSelection(selectionId, data);
    dispatch(
      setSelectionUpdateSuccess({
        keys: Object.keys(data) as Array<keyof SelectionsAPI.UpdateSelectionData>,
        data: selection,
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateTemporarySelection = (
  selectionId: number,
  data: Partial<SelectionsAPI.CreateSelectionData>
): AppThunk => async (dispatch) => {
  const { setSelectionUpdateSuccess } = slice.actions;

  dispatch(
    setSelectionUpdateSuccess({
      keys: Object.keys(data) as Array<keyof SelectionsAPI.UpdateSelectionData>,
      data: { ...data, id: selectionId },
    })
  );
};

export const deleteSelection = (selectionId: number): AppThunk => async (dispatch) => {
  const { deleteSelection } = slice.actions;

  try {
    dispatch(deleteSelection({ id: selectionId }));
    await SelectionsAPI.deleteSelection(selectionId);
  } catch (error) {
    console.error(error);
  }
};

export const shareAnnotation = (data: ShareAnnotationPayload): AppThunk => async (dispatch) => {
  try {
    await SelectionsAPI.shareAnnotation(data);
    const { shareAnnotationSuccess } = slice.actions;
    dispatch(shareAnnotationSuccess());
  } catch (error) {
    console.error(error);
    const { shareAnnotationFail } = slice.actions;
    dispatch(shareAnnotationFail());
  }
};

export const shareGeneralNote = (data: ShareGeneralNotePayload): AppThunk => async (dispatch) => {
  try {
    await GeneralNotesAPI.shareGeneralNote(data);
    const { shareGeneralNoteSuccess } = slice.actions;
    dispatch(shareGeneralNoteSuccess());
  } catch (error) {
    console.error(error);
    const { shareGeneralNoteFail } = slice.actions;
    dispatch(shareGeneralNoteFail());
  }
};

export const deleteTemporarySelection = (selectionId: number): AppThunk => (dispatch) => {
  const { deleteSelection } = slice.actions;

  dispatch(deleteSelection({ id: selectionId }));
};

export const deleteComment = (selectionId: number, commentId: number): AppThunk => async (
  dispatch
) => {
  const { deleteSelectionDataItem, changeSelectionType } = slice.actions;

  try {
    dispatch(deleteSelectionDataItem({ selectionId, id: commentId }));
    dispatch(changeSelectionType({ selectionId, isAnnotation: false }))

    await SelectionsAPI.deleteComment(commentId);
  } catch (error) {
    console.error(error);
  }
};

export const updateComment = (
  selectionId: number,
  commentId: number,
  data: SelectionsAPI.UpdateCommentData
): AppThunk => async (dispatch) => {
  const { updateSelectionDataItem } = slice.actions;

  try {
    dispatch(updateSelectionDataItem({ selectionId, id: commentId, ...data }));
    await SelectionsAPI.updateComment(commentId, data);
  } catch (error) {
    console.error(error);
  }
};

export const addComment = (data: SelectionsAPI.CreateCommentData): AppThunk => async (dispatch) => {
  const { addSelectionDataItem, changeSelectionType } = slice.actions;

  try {
    const comment = await SelectionsAPI.createComment(data);
    dispatch(addSelectionDataItem(comment));
    dispatch(changeSelectionType({ selectionId: data.selectionId, isAnnotation: true }));
  } catch (error) {
    console.error(error);
  }
};

export const deleteAttachment = (selectionId: number, attachmentId: number): AppThunk => async (
  dispatch
) => {
  const { deleteSelectionDataItem, changeSelectionType } = slice.actions;

  try {
    dispatch(deleteSelectionDataItem({ selectionId, id: attachmentId }));
    dispatch(changeSelectionType({ selectionId, isAnnotation: false }));
    await SelectionsAPI.deleteAttachment(attachmentId);
  } catch (error) {
    console.error(error);
  }
};

export const updateAttachment = (
  selectionId: number,
  attachmentId: number,
  data: SelectionsAPI.UpdateAttachmentData
): AppThunk => async (dispatch) => {
  const { updateSelectionDataItem } = slice.actions;

  try {
    dispatch(updateSelectionDataItem({ selectionId, id: attachmentId, ...data }));
    await SelectionsAPI.updateAttachment(attachmentId, data);
  } catch (error) {
    console.error(error);
  }
};

export const addAttachment = (data: SelectionsAPI.CreateAttachmentData): AppThunk => async (
  dispatch
) => {
  const { addSelectionDataItem, changeSelectionType } = slice.actions;

  try {
    const attachment = await SelectionsAPI.createAttachment(data);
    dispatch(changeSelectionType({ selectionId: data.selectionId, isAnnotation: true }));
    dispatch(addSelectionDataItem(attachment));
  } catch (error) {
    console.error(error);
  }
};

export const debouncedSetScale = debounceAction(setScale, 200);
export const debouncedSetPageNumber = debounceAction(slice.actions.setPageNumber, 200);

export const updateScale = (scale: number): AppThunk => (dispatch) => {
  dispatch(setToolbarScale(scale));
  dispatch(debouncedSetScale(scale));
};

export const updatePageNumber = (pageNumber: number): AppThunk => (dispatch) => {
  dispatch(setToolbarPageNumber(pageNumber));
  dispatch(debouncedSetPageNumber(pageNumber));
};

export const setPageNumber = (pageNumber: number): AppThunk => (dispatch) => {
  dispatch(setToolbarPageNumber(pageNumber));
  dispatch(slice.actions.setPageNumber(pageNumber));
};

export const getGeneralNotes = (documentId: number): AppThunk => async (dispatch) => {
  const { setGeneralNotesStart, setGeneralNotesSuccess, setGeneralNotesFailure } = slice.actions;

  try {
    dispatch(setGeneralNotesStart());
    const generalNotes = await GeneralNotesAPI.getGeneralNotes(documentId);
    dispatch(setGeneralNotesSuccess(generalNotes));
  } catch (error) {
    dispatch(setGeneralNotesFailure(error));
  }
};

export const addGeneralNote = (data: GeneralNotesAPI.CreateGeneralNoteData): AppThunk => async (
  dispatch
) => {
  const { addGeneralNote } = slice.actions;

  try {
    const generalNote = await GeneralNotesAPI.createGeneralNote(data);
    dispatch(addGeneralNote(generalNote));
  } catch (error) {
    console.error(error);
  }
};

export const updateGeneralNote = (
  generalNoteId: number,
  data: GeneralNotesAPI.UpdateGeneralNoteData
): AppThunk => async (dispatch) => {
  const { updateGeneralNote } = slice.actions;

  try {
    dispatch(updateGeneralNote({ id: generalNoteId, ...data }));

    await GeneralNotesAPI.updateGeneralNote(generalNoteId, data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteGeneralNote = (generalNoteId: number): AppThunk => async (dispatch) => {
  const { deleteGeneralNote } = slice.actions;

  try {
    dispatch(deleteGeneralNote({ id: generalNoteId }));
    await GeneralNotesAPI.deleteGeneralNote(generalNoteId);
  } catch (error) {
    console.error(error);
  }
};

/*
 * @deprecated use selections with isBookmark flag
 */
export const getBookmarks = (documentId: number): AppThunk => async (dispatch) => {
  const { setBookmarksStart, setBookmarksSuccess, setBookmarksFailure } = slice.actions;

  try {
    dispatch(setBookmarksStart());
    const bookmarks = await BookmarksAPI.getBookmarks(documentId);
    dispatch(setBookmarksSuccess(bookmarks));
  } catch (error) {
    dispatch(setBookmarksFailure(error));
  }
};

export const addBookmark = (data: BookmarksAPI.CreateBookmarkData): AppThunk => async (
  dispatch
) => {
  const { addBookmark } = slice.actions;

  try {
    const bookmark = await BookmarksAPI.createBookmark(data);
    dispatch(addBookmark(bookmark));
  } catch (error) {
    console.error(error);
  }
};

export const updateBookmark = (
  bookmarkId: number,
  data: BookmarksAPI.UpdateBookmarkData
): AppThunk => async (dispatch) => {
  const { updateBookmark } = slice.actions;

  try {
    dispatch(updateBookmark({ id: bookmarkId, ...data }));
    await BookmarksAPI.updateBookmark(bookmarkId, data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteBookmark = (bookmarkId: number): AppThunk => async (dispatch) => {
  const { deleteBookmark } = slice.actions;

  try {
    dispatch(deleteBookmark({ id: bookmarkId }));
    await BookmarksAPI.deleteBookmark(bookmarkId);
  } catch (error) {
    console.error(error);
  }
};

export const deleteGeneralNoteAttachment = (
  noteId: number,
  attachmentId: number
): AppThunk => async (dispatch) => {
  const { deleteGeneralNoteDataItem } = slice.actions;

  try {
    dispatch(deleteGeneralNoteDataItem({ noteId, id: attachmentId }));
    await GeneralNotesAPI.deleteAttachment(attachmentId);
  } catch (error) {
    console.error(error);
  }
};

export const updateGeneralNoteAttachment = (
  noteId: number,
  attachmentId: number,
  data: GeneralNotesAPI.UpdateAttachmentData
): AppThunk => async (dispatch) => {
  const { updateGeneralNoteDataItem } = slice.actions;

  try {
    dispatch(updateGeneralNoteDataItem({ noteId, id: attachmentId, ...data }));
    await GeneralNotesAPI.updateGeneralNoteAttachment(attachmentId, data);
  } catch (error) {
    console.error(error);
  }
};

export const addGeneralNoteAttachment = (
  data: GeneralNotesAPI.CreateAttachmentData
): AppThunk => async (dispatch) => {
  const { addGeneralNoteDataItem } = slice.actions;

  try {
    const attachment = await GeneralNotesAPI.createAttachment(data);
    dispatch(addGeneralNoteDataItem(attachment));
  } catch (error) {
    console.error(error);
  }
};

export const addNewColor = (codeId: number, color: string): AppThunk => async (dispatch) => {
  const { setAddNewColor } = slice.actions;

  try {
    const updatedColors = await CodesAPI.addNewColor(codeId, color);

    dispatch(setAddNewColor(updatedColors.colors));
  } catch (error) {
    console.error(error);
  }
};

export const reviewChanges = (codeId: number, review: ReviewCodeUpdate): AppThunk => async (
  dispatch
) => {
  const { updateCodeAfterReview, setReviewError } = slice.actions;
  try {
    const newCode = await CodesAPI.reviewUpdate(codeId, review);
    dispatch(updateCodeAfterReview(newCode));
  } catch (error) {
    dispatch(setReviewError(error.response?.data));
    console.error(error);
  }
};
