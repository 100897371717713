import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import App from './components/App';
import store from './store';
import theme from './theme';
import GlobalStyles from './components/GlobalStyles/GlobalStyles';
import ToastConfig from './components/Toast/ToastConfig';
import * as serviceWorker from './serviceWorker';

ReactModal.setAppElement('body');

toast.configure(ToastConfig);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistStore(store)}>
      <Router>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyles />
            <App />
          </>
        </ThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
