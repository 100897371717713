import { Dispatch } from 'redux';
import { Options } from 'recordrtc';

import {
  IDocument,
  IDocumentChange,
  ISelection,
  ISelectionCoordinates,
  SelectionColor,
  SelectionType,
} from '@codex/shared';
import { isSelectionOverlapping } from '@codex/shared/functions/selection';

import {
  addSelection as addSelectionAction,
  addTemporarySelection as addTemporarySelectionAction,
  ISelections,
} from './codeEditSlice';
import { calculateNodeOffset } from 'components/Pdf/utils';
import { Positions } from './types';

export enum SupportedMediaNoteFormat {
  VIDEO = 'video',
  AUDIO = 'audio',
}

const REQUIRED_MEDIA: Record<SupportedMediaNoteFormat, MediaStreamConstraints> = {
  video: {
    audio: true,
    video: true,
  },
  audio: {
    audio: true,
  },
};

const RECORD_OPTIONS: Record<SupportedMediaNoteFormat, Options> = {
  video: {
    type: 'video',
    // @ts-ignore
    mimeType: 'video/webm;codecs=vp8,opus',
  },
  audio: {
    type: 'video',
    // @ts-ignore
    mimeType: 'video/webm;codecs=opus',
  },
};

function findPageNumber(element: HTMLElement | null) {
  if (element === null) return null;

  const pageNumber = element.getAttribute('page-number');

  if (pageNumber) {
    return pageNumber;
  }

  return findPageNumber(element.parentElement);
}

const getSelectionCoordinates = (
  selectionsDivs: { page: number; textDivs: HTMLSpanElement[] }[],
  type?: SelectionType
): [Selection, ISelectionCoordinates] => {
  const windowSelection = window.getSelection();
  if (!windowSelection || windowSelection.isCollapsed) {
    const message =
      type === SelectionType.COMMENT
        ? 'Please, first select the text you want to annotate.'
        : type === SelectionType.ATTACHMENT
        ? 'Please, first select the text you want to attach files to.'
        : 'Please, first select the text you want to highlight.';

    throw new Error(message);
  }

  const { anchorNode, focusNode, anchorOffset, focusOffset } = windowSelection;

  if (!anchorNode || !focusNode) {
    throw new Error('Something went wrong. Please try again.');
  }

  const selectedPageNumber = findPageNumber(anchorNode.parentElement);

  const startSpan = anchorNode.parentElement?.classList.contains('highlight')
    ? anchorNode.parentElement.parentElement
    : anchorNode.parentElement;
  const endSpan = focusNode.parentElement?.classList.contains('highlight')
    ? focusNode.parentElement.parentElement
    : focusNode.parentElement;

  if (!startSpan || !endSpan || !selectedPageNumber) {
    throw new Error('Something went wrong. Please try again.');
  }

  const selectionOnRenderedPages = selectionsDivs.find(
    (selections) => selections.page === Number(selectedPageNumber)
  );

  if (!selectionOnRenderedPages) {
    throw new Error(
      'You cannot select a text outside of a current document page. Please try again.'
    );
  }

  const startOffset = calculateNodeOffset(
    startSpan,
    anchorNode.parentElement
      ? anchorNode.parentElement.classList.contains('highlight')
        ? anchorNode.parentElement
        : anchorNode
      : anchorNode
  );
  const endOffset = calculateNodeOffset(
    endSpan,
    focusNode.parentElement
      ? focusNode.parentElement.classList.contains('highlight')
        ? focusNode.parentElement
        : focusNode
      : focusNode
  );
  const isSameElement = startSpan === endSpan;
  const startElement = selectionOnRenderedPages.textDivs.findIndex((textDiv) => {
    const selectedStyle = textDiv.style;
    const startSpanStyle = startSpan.style;

    return selectedStyle.top === startSpanStyle.top && selectedStyle.left === startSpanStyle.left;
  });
  const startCharacter = startOffset + anchorOffset;
  const endElement = isSameElement
    ? startElement
    : selectionOnRenderedPages.textDivs.findIndex((textDiv) => {
        const selectedStyle = textDiv.style;
        const endSpanStyle = endSpan.style;

        return selectedStyle.top === endSpanStyle.top && selectedStyle.left === endSpanStyle.left;
      });
  const endCharacter = endOffset + focusOffset;
  const isFlipped = startElement > endElement || (isSameElement && startCharacter > endCharacter);

  if ([startElement, endElement].includes(-1)) {
    throw new Error('Something went wrong. Please try again.');
  }

  const selection = {
    startPage: selectionOnRenderedPages.page - 1,
    startElement,
    startCharacter,
    endPage: selectionOnRenderedPages.page - 1,
    endElement,
    endCharacter,
  };

  if (isFlipped) {
    selection.startElement = endElement;
    selection.startCharacter = endCharacter;
    selection.endElement = startElement;
    selection.endCharacter = startCharacter;
  }

  return [windowSelection, selection];
};

export const addSelection = (
  color: SelectionColor | string,
  dispatch: Dispatch<any>,
  selections: ISelections,
  document?: IDocument | null,
  selectionsDivs?: { page: number; textDivs: HTMLSpanElement[] }[],
  isBookmark: boolean = false
) => {
  if (!selectionsDivs || !document) {
    return;
  }

  const [windowSelection, selection] = getSelectionCoordinates(selectionsDivs);

  if (isSelectionOverlapping(selection, selections)) {
    throw new Error(
      'You cannot select a text that is overlapping with already existing annotation(s). Please try again.'
    );
  }

  const text = windowSelection.toString();

  dispatch(
    addSelectionAction({
      ...selection,
      color,
      text,
      documentId: document.id,
      isBookmark,
      isAnnotation: false,
      name: isBookmark ? text.slice(0, 255) : undefined,
    })
  );

  windowSelection.removeAllRanges();
};

export const addTemporarySelection = (
  type: SelectionType,
  color: SelectionColor,
  dispatch: Dispatch<any>,
  selections: ISelections,
  document?: IDocument | null,
  selectionsDivs?: { page: number; textDivs: HTMLSpanElement[] }[],
  mediaNoteType?: SupportedMediaNoteFormat
) => {
  if (!selectionsDivs || !document) {
    return;
  }

  const [windowSelection, selection] = getSelectionCoordinates(selectionsDivs, type);

  if (isSelectionOverlapping(selection, selections)) {
    throw new Error(
      'You cannot select a text that is overlapping with already existing annotation(s). Please try again.'
    );
  }

  dispatch(
    addTemporarySelectionAction({
      type,
      selection: {
        ...selection,
        color,
        text: windowSelection.toString(),
        documentId: document.id,
        isBookmark: false,
        isAnnotation: type === SelectionType.COMMENT,
      },
      mediaNoteType,
    })
  );

  windowSelection.removeAllRanges();
};

export const getMediaStream = async (
  type: SupportedMediaNoteFormat,
  captureScreen: boolean
): Promise<{ stream: MediaStream; options: Options }> => {
  if (!navigator.mediaDevices) throw new Error('Cannot get media devices');

  const requiredMedias = REQUIRED_MEDIA[type];
  const recordOptions = RECORD_OPTIONS[type];

  if (!captureScreen) {
    const stream = await navigator.mediaDevices.getUserMedia(requiredMedias);
    return {
      stream,
      options: recordOptions,
    };
  }

  const screenStream = await navigator.mediaDevices.getDisplayMedia({
    video: true,
  });

  const audioStream = await navigator.mediaDevices.getUserMedia(REQUIRED_MEDIA.audio);
  const audioTracks = audioStream.getTracks();
  if (audioTracks.length) {
    screenStream.addTrack(audioTracks[0]);
  }

  return {
    stream: screenStream,
    options: recordOptions,
  };
};

export const isSelection = (selection: any): selection is ISelection =>
  (selection as ISelection).id > 0;

const positionsKey = 'positions';

export const getDocumentsPositions = (): Positions | null => {
  const positions: string | null = localStorage.getItem(positionsKey);
  return positions && JSON.parse(positions);
};

export const saveDocumentsPositions = (positions: Positions): void => {
  localStorage.setItem(positionsKey, JSON.stringify(positions));
};

export const clearDocumentsPositions = (): void => {
  localStorage.removeItem(positionsKey);
};

export const findSavedPageNumber = (
  codeId: number,
  documentId: number | undefined
): number | null => {
  const positions: Positions | null = getDocumentsPositions();
  return (
    (documentId && positions !== null && positions[codeId] && positions[codeId][documentId]) || null
  );
};

export const getTooltipLabel = (
  deletedDocuments: IDocumentChange[],
  createdDocuments: IDocumentChange[],
  renamedDocuments: IDocumentChange[],
  reorderedDocuments: IDocumentChange[],
  documentsToUpdate: number[] | undefined,
  id: number,
  copiedFromDocumentId?: number
) => {
  const isDeleted = deletedDocuments.map((doc) => doc.id).includes(id);
  const isAdded = createdDocuments.map((doc) => (doc.payload as IDocument).id).includes(id);
  const isUpdated = documentsToUpdate && documentsToUpdate.includes(id);
  const isRenamed = renamedDocuments.map((doc) => doc.id).includes(copiedFromDocumentId);
  const isReordered = reorderedDocuments.map((doc) => doc.id).includes(copiedFromDocumentId);

  if (isDeleted) {
    return 'This document has been deleted';
  }

  if (isAdded) {
    return 'This document has been added';
  }

  if (isUpdated && isRenamed && isReordered) {
    return 'This document has been renamed, reordered and contains updates to resolve';
  }

  if (isRenamed && isReordered) {
    return 'This document has been renamed and reordered';
  }

  if (isUpdated && isReordered) {
    return 'This document has been reordered and contains updates to resolve';
  }

  if (isUpdated && isRenamed) {
    return 'This document has been renamed and contains updates to resolve';
  }

  if (isReordered) {
    return 'This document has been reordered';
  }

  if (isRenamed) {
    return 'This document has been renamed';
  }

  if (isUpdated) {
    return 'This document contains updates to resolve';
  }

  return false;
};
