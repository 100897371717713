import { FileContentType } from '../requests';
import { IOwnerInfo } from '..';

export enum SelectionType {
  COMMENT = 'comment',
  ATTACHMENT = 'attachment',
}

export enum SelectionColor {
  COLOR_1 = 'color-1',
  COLOR_2 = 'color-2',
  COLOR_3 = 'color-3',
  COLOR_4 = 'color-4',
  COLOR_5 = 'color-5',
  COLOR_6 = 'color-6',
  COLOR_7 = 'color-7',
  COLOR_8 = 'color-8',
  COLOR_9 = 'color-9',
  COLOR_10 = 'color-10',
  COLOR_11 = 'color-11',
  COLOR_12 = 'color-12',
  COLOR_13 = 'color-13',
  COLOR_14 = 'color-14',
  COLOR_15 = 'color-15',
  COLOR_16 = 'color-16',
  COLOR_17 = 'color-17',
  COLOR_18 = 'color-18',
  COLOR_19 = 'color-19',
  COLOR_20 = 'color-20',
}

export type SelectionColors = SelectionColor | string;

export enum SelectionStatus {
  SUCCESS,
  UNCONFIRMED,
  FAILED,
}

export const VALID_SELECTION_STATUSES = [SelectionStatus.SUCCESS];
export const INVALID_SELECTION_STATUSES = [
  SelectionStatus.UNCONFIRMED,
  SelectionStatus.FAILED,
];

export interface ISelectionCoordinates {
  startPage: number;
  startElement: number;
  startCharacter: number;
  endPage: number;
  endElement: number;
  endCharacter: number;
}

type SelectionData = {
  id: number;
  selectionId: number;
  owner: IOwnerInfo;
  isDeleted: boolean;
  created_at: string;
  updated_at: string;
};

export interface ISelectionComment extends SelectionData {
  text: string;
  type: SelectionType.COMMENT;
}

export interface ISelectionAttachment extends SelectionData {
  uuid: string;
  title: string;
  size: number;
  contentType: FileContentType;
  type: SelectionType.ATTACHMENT;
}

export interface ISelection extends ISelectionCoordinates {
  id: number;
  documentId: number;
  owner: IOwnerInfo;
  color: SelectionColors;
  text: string;
  isDeleted: boolean;
  status: SelectionStatus;
  data: Array<ISelectionComment | ISelectionAttachment>;
  created_at: string;
  updated_at: string;
  isBookmark: boolean;
  isAnnotation: boolean;
  name?: string;
}

export interface IChange extends ISelection {
  previousText?: string;
}

export type SelectionAttachmentDeleteEventData = Pick<
  SelectionData,
  'id' | 'selectionId'
>;

export type SelectionCommentDeleteEventData = Pick<
  SelectionData,
  'id' | 'selectionId'
>;
