import { combineReducers } from '@reduxjs/toolkit';

import codesReducer from 'features/codes/codesSlice';
import codeCreateReducer from 'features/codeCreate/codeCreateSlice';
import codeEditReducer from 'features/codeEdit/codeEditSlice';
import codeEditSearchReducer from 'features/codeEditSearch/codeEditSearchSlice';
import codeDocumentVersionsReducer from 'features/codeDocumentVersions/codeDocumentVersionsSlice';
import codeEditDetailsReducer from 'features/codeEditDetails/codeEditDetailsSlice';
import codeOrganizeDocumentsReducer from 'features/codeOrganizeDocuments/codeOrganizeDocumentsSlice';
import codeUnportedAnnotationsReducer from 'features/codeUnportedAnnotations/unportedAnnotationsSlice';
import codeShareReducer from 'features/codeShare/codeShareSlice';
import libraryReducer from 'features/library/librarySlice';
import authReducer from 'features/auth/authSlice';
import notificationsReducer from 'features/notifications/notificationsSlice';
import wsConnectionReducer from 'features/wsConnection/wsConnectionSlice';

const rootReducer = combineReducers({
  codes: codesReducer,
  codeCreate: codeCreateReducer,
  codeEdit: codeEditReducer,
  codeEditSearch: codeEditSearchReducer,
  codeEditDetails: codeEditDetailsReducer,
  codeDocumentVersions: codeDocumentVersionsReducer,
  codeOrganizeDocuments: codeOrganizeDocumentsReducer,
  codeUnportedAnnotations: codeUnportedAnnotationsReducer,
  codeShare: codeShareReducer,
  library: libraryReducer,
  auth: authReducer,
  notifications: notificationsReducer,
  wsConnection: wsConnectionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
