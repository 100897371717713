import { IUser } from '@codex/shared/interfaces';

import api from './index';

export async function updateProfile(data: Partial<IUser>): Promise<IUser> {
  const url = '/api/v1/profile';
  const response = await api.patch<IUser>(url, data);

  return response.data;
}

export async function getProfile(): Promise<IUser> {
  const url = '/api/v1/profile';
  const response = await api.get<IUser>(url);

  return response.data;
}

export async function deleteProfile(): Promise<void> {
  const url = '/api/v1/profile';
  await api.delete(url);
}

export async function changePassword(data: {
  currentPassword: string;
  newPassword: string;
}): Promise<void> {
  const url = '/api/v1/profile/password';
  await api.post(url, data);
}

export async function generateTwoFactorQrUrl(data: {
  userId: number;
}): Promise<{ qrCodeUrl: string }> {
  const url = '/api/v1/profile/2fa/generate';
  const response = await api.post(url, data);

  return response.data;
}

export async function toggleTwoFactorActivation(data: {
  activate: boolean;
  token: string;
}): Promise<void> {
  const url = '/api/v1/profile/2fa/activation';
  await api.post(url, data);
}
