import React from 'react';
import styled, { css } from 'styled-components/macro';

type BaseIconProps = React.HTMLAttributes<HTMLElement> & {
  hasMarginRight?: boolean;
  hasMarginLeft?: boolean;
  size?: number;
};

const BaseIcon = ({ size, hasMarginLeft, hasMarginRight, ...rest }: BaseIconProps) => (
  <i {...rest} />
);

export const Icon = styled(BaseIcon)`
  font-family: 'Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  flex-shrink: 0;
  user-select: none;

  &:before {
    display: block;
  }

  ${({ size }) =>
    size &&
    css`
      font-size: ${size}px;
    `};

  ${({ hasMarginRight }) =>
    hasMarginRight &&
    css`
      margin-right: 12px;
    `};

  ${({ hasMarginLeft }) =>
    hasMarginLeft &&
    css`
      margin-left: 12px;
    `};
`;

export const ArrowBackwardIcon = styled(Icon)`
  &:before {
    content: '\\e991';
  }
`;

export const ArrowForwardIcon = styled(Icon)`
  &:before {
    content: '\\e999';
  }
`;

export const CaretUpIcon = styled(Icon)`
  &:before {
    content: '\\e9af';
  }
`;

export const CaretDownIcon = styled(Icon)`
  &:before {
    content: '\\e9ac';
  }
`;

export const SearchIcon = styled(Icon)`
  &:before {
    content: '\\e955';
  }
`;

export const SettingsIcon = styled(Icon)`
  &:before {
    content: '\\ecdb';
  }
`;

export const DoneIcon = styled(Icon)`
  &:before {
    content: '\\e926';
  }
`;

export const CheckmarkCircleIcon = styled(Icon)`
  &:before {
    content: '\\e919';
  }
`;

export const ClearIcon = styled(Icon)`
  &:before {
    content: '\\e920';
  }
`;

export const AddIcon = styled(Icon)`
  &:before {
    content: '\\e904';
  }
`;

export const UploadIcon = styled(Icon)`
  &:before {
    content: '\\ece4';
  }
`;

export const FileIcon = styled(Icon)`
  &:before {
    content: '\\ea6a';
  }
`;

export const VoiceIcon = styled(Icon)`
  &:before {
    content: '\\e9e0';
  }
`;

export const PhotoSquareOutlinedIcon = styled(Icon)`
  &:before {
    content: '\\eb6b';
  }
`;

export const VideoCameraIcon = styled(Icon)`
  &:before {
    content: '\\ea5c';
  }
`;

export const PdfIcon = styled(FileIcon)`
  color: #ee4135;
`;

export const DocIcon = styled(FileIcon)`
  color: #2a5599;
`;

export const Mp3Icon = styled(VoiceIcon)`
  color: var(--color-info);
`;

export const ImageIcon = styled(PhotoSquareOutlinedIcon)`
  color: var(--color-info);
`;

export const Mp4Icon = styled(VideoCameraIcon)`
  color: var(--color-info);
`;

export const InfoOutlinedIcon = styled(Icon)`
  &:before {
    content: '\\e937';
  }
`;

export const InfoIcon = styled(Icon)`
  color: var(--color-info);

  &:before {
    content: '\\e938';
  }
`;

export const WarningInfoIcon = styled(InfoIcon)`
  color: var(--color-warning);
`;

export const MoreVerticalIcon = styled(Icon)`
  &:before {
    content: '\\ecd3';
  }
`;

export const CommentIcon = styled(Icon)`
  &:before {
    content: '\\e9fa';
  }
`;

export const FilesIcon = styled(Icon)`
  &:before {
    content: '\\ea6e';
  }
`;

export const StrokeWeightIcon = styled(Icon)`
  &:before {
    content: '\\eb83';
  }
`;

export const BookmarksIcon = styled(Icon)`
  &:before {
    content: '\\e90d';
  }
`;

export const PanoramaVsIcon = styled(Icon)`
  &:before {
    content: '\\eb60';
  }
`;

export const ChevronUpIcon = styled(Icon)`
  &:before {
    content: '\\e9b3';
  }
`;

export const ChevronRightIcon = styled(Icon)`
  &:before {
    content: '\\e9b2';
  }
`;

export const ChevronDownIcon = styled(Icon)`
  &:before {
    content: '\\e9b0';
  }
`;

export const ChevronLeftIcon = styled(Icon)`
  &:before {
    content: '\\e9b1';
  }
`;

export const RemoveIcon = styled(Icon)`
  &:before {
    content: '\\e953';
  }
`;

export const HighlightIcon = styled(Icon)`
  &:before {
    content: '\\ecf6';
  }
`;

export const AttachmentDiagonalIcon = styled(Icon)`
  &:before {
    content: '\\eca3';
  }
`;

export const BookmarkIcon = styled(Icon)`
  &:before {
    content: '\\e90b';
  }
`;

export const NewEmailIcon = styled(Icon)`
  &:before {
    content: '\\ea12';
  }
`;

export const AnnotationsIcon = styled(Icon)`
  &:before {
    content: '\\ecf5';
  }
`;

export const DeleteIcon = styled(Icon)`
  color: var(--color-danger);

  &:before {
    content: '\\ecad';
  }
`;

export const DocumentsIcon = styled(Icon)`
  &:before {
    content: '\\ea64';
  }
`;

export const SaveIcon = styled(Icon)`
  &:before {
    content: '\\eb75';
  }
`;

export const UserIcon = styled(Icon)`
  &:before {
    content: '\\ecf3';
  }
`;

export const PasswordIcon = styled(Icon)`
  &:before {
    content: '\\ecd6';
  }
`;

export const SignOutIcon = styled(Icon)`
  &:before {
    content: '\\ecdf';
  }
`;

export const EditIcon = styled(Icon)`
  &:before {
    content: '\\ecb1';
  }
`;

export const VisibleIcon = styled(Icon)`
  &:before {
    content: '\\ece6';
  }
`;

export const HiddenIcon = styled(Icon)`
  &:before {
    content: '\\ecbe';
  }
`;

export const BellIcon = styled(Icon)`
  &:before {
    content: '\\e82e';
  }
`;

export const FilterIcon = styled(Icon)`
  &:before {
    content: '\\e82d';
  }
`;

export const BriefCaseIcon = styled(Icon)`
  &:before {
    content: '\\e830';
  }
`;

export const CollaboratorsIcon = styled(Icon)`
  &:before {
    content: '\\e831';
  }
`;

export const CloseIcon = styled(Icon)`
  &:before {
    content: '\\e800';
  }
`;

export const MenuIcon = styled(Icon)`
  &:before {
    content: '\\e82f';
  }
`;
