export enum SocketSubscriptionType {
  CODES = 'codes',
  USERS = 'users',
  INTERNAL_USER = 'internal-user',
}

export interface ISubscriptionData {
  id: number;
  headers: {
    authorization: string;
  };
}

export interface ISocketSubscribe {
  event: SocketSubscriptionType;
  data: ISubscriptionData;
}
