import { createGlobalStyle } from 'styled-components/macro';

const ModalGlobalStyles = createGlobalStyle`
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(45, 46, 50, 0.64);
  }

  .--hide-for-mobile {
    display: none;

    ${({ theme }) => theme.media.md`
      display: block;
    `}
  }

  .--hide-for-desktop {
    display: block;

    ${({ theme }) => theme.media.md`
      display: none;
    `}
  }
`;

export default ModalGlobalStyles;
