import { IListResponse, IBookmark } from '@codex/shared';

import api from './index';

export type BookmarksResponse = IListResponse<IBookmark>;
export type CreateBookmarkData = Pick<IBookmark, 'documentId' | 'name' | 'page'>;
export type UpdateBookmarkData = Pick<IBookmark, 'name'>;

export async function getBookmarks(documentId: number): Promise<IBookmark[]> {
  const url = `/api/v1/documents/${documentId}/bookmarks`;
  const {
    data: { data },
  } = await api.get<BookmarksResponse>(url);

  return data;
}

export async function createBookmark(data: CreateBookmarkData): Promise<IBookmark> {
  const url = '/api/v1/bookmarks';
  const response = await api.post<IBookmark>(url, data);

  return response.data;
}

export async function updateBookmark(
  bookmarkId: number,
  data: UpdateBookmarkData
): Promise<IBookmark> {
  const url = `/api/v1/bookmarks/${bookmarkId}`;
  const response = await api.patch<IBookmark>(url, data);

  return response.data;
}

export async function deleteBookmark(bookmarkId: number): Promise<void> {
  const url = `/api/v1/bookmarks/${bookmarkId}`;
  await api.delete(url);
}
