import { IUser } from '@codex/shared/interfaces';

import api from './index';

export interface UserResponse {
  token: string;
  device2faToken: string;
  user: IUser | null;
}

export type RegisterData = Partial<IUser> & { password: string };

export async function register(data: RegisterData): Promise<UserResponse> {
  const url = '/api/v1/auth/register';
  const response = await api.post<UserResponse>(url, data);

  return response.data;
}

export async function login(data: {
  email: string;
  password: string;
  token?: string;
  device2faToken?: string;
}): Promise<UserResponse> {
  const url = '/api/v1/auth/login';
  const response = await api.post<UserResponse>(url, data);

  return response.data;
}

export async function forgotPassword(email: string): Promise<void> {
  const url = '/api/v1/auth/forgot-password';
  await api.post(url, { email });
}

export async function resetPassword(token: string, newPassword: string): Promise<void> {
  const url = `/api/v1/auth/reset-password/${token}`;
  await api.post(url, { newPassword });
}

export async function activateAccount(token: string): Promise<void> {
  const url = '/api/v1/auth/activation';
  await api.post<UserResponse>(`${url}/${token}`);
}

export async function resendToken(email: string): Promise<void> {
  const url = '/api/v1/auth/activation/resend';
  await api.post<UserResponse>(url, { email });
}
