export enum Size {
  SM = 'sm',
  MD = 'md',
  ML = 'ml',
  LG = 'lg',
  XL = 'xl',
}

export const BREAKPOINT_SIZE = {
  [Size.SM]: '500px',
  [Size.MD]: '769px',
  [Size.ML]: '1036px',
  [Size.LG]: '1250px',
  [Size.XL]: '1441px',
};

export type MediaQueries = {
  [key in Size]: Function;
};

const mediaQueries = (Object.keys(BREAKPOINT_SIZE) as Array<keyof typeof BREAKPOINT_SIZE>).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (style: string) => `@media (min-width: ${BREAKPOINT_SIZE[key]}) { ${style} }`,
  }),
  {} as MediaQueries
);

export default mediaQueries;
